export enum NAMESPACE {
  COMMON = "common",
  APP = "app",
  USER = "user",
  ORGANIZATION = "organization",
  HOME = "home",
  READER = "readers"
}

export const getCommonNS = () => {
  return { ns: NAMESPACE.COMMON };
};
