import { Modal, Tabs } from "@hid-galaxy-ui/galaxy-react";
import { ModalVariants } from "@hid-galaxy-ui/galaxy-react/components/Modal/modalEnums";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../../utils/i18nUtils";
import { IDevices } from "../../downloadGateway/types";
import GeneralInfo, { IDeviceInfo } from "./general";

interface IGateWayDeviceInfoProps {
  closeDeviceInfoModal: (e: React.MouseEvent<HTMLElement>) => void;
  selectedDevice: IDevices;
  onChangeNameAndDesc: (data: IDeviceInfo) => void;
}

export default function GateWayDeviceInfo(props: IGateWayDeviceInfoProps) {
  const { t } = useTranslation(NAMESPACE.READER);



  return (
    <Modal
      size={ModalVariants.Large}
      onClose={props.closeDeviceInfoModal}
      isFocusTrapActive={false}
    >
      <div className="hid-origo__linq">
        <GeneralInfo selectedDevice={props.selectedDevice} onSave={props.onChangeNameAndDesc}/>
      </div>
    </Modal>
  );
}
