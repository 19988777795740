import { default as HeaderComp } from "../HeaderComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changeOrganizationPath, loginPath } from "../../utils/routes";
import { getCommonNS, NAMESPACE } from "../../utils/i18nUtils";
import { useDispatch, useSelector } from "react-redux";
import { loginInfoData, resetLoginInfo } from "../../reducers/userInfoReducer";
import {
  resetNotification,
  resetStateToDefault,
} from "../../reducers/applyTemplateReducer";
import { resetFWStateToDefault } from "../../reducers/upgradeFirmwareReducer";
import {
  clearReaders,
  resetReaders,
  setPageFrom,
} from "../../reducers/discoverReadersReducer";
import { Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import "./header.scss";
import { callLogoutService } from "../../services/loginAPIService";
interface IHeader {
  title?: string;
}
const Header = (props: IHeader) => {
  const { t } = useTranslation(NAMESPACE.READER);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { title = t("COMMON.HEADER_TITLE_LINQ", getCommonNS()) } = props;
  const { linq_enabled_orgs } = useSelector(loginInfoData);
  const actions = [
    {
      icon: "circleUser",
      key: "user",
      subNavItems: [
        {
          data: [
            {
              key: "organizations",
              value: t("READERS.ORGANIZATIONS"),
            },
            {
              key: "logout",
              value: t("COMMON.SIGNOUT", getCommonNS()),
            },
          ],
        },
      ],
    },
  ];

  const onActionClick = (e: any, key: any) => {
    switch (key) {
      case "organizations":
        navigate(changeOrganizationPath);
        break;
      case "logout":
        onLogout();
    }
  };

  const onLogout = async () => {
    try {
      await callLogoutService();
      localStorage.clear();
      dispatch(resetStateToDefault());
      dispatch(resetFWStateToDefault());
      dispatch(resetNotification());
      dispatch(resetLoginInfo());
      dispatch(setPageFrom("list"));
      dispatch(clearReaders());
      navigate(loginPath);
    } catch {}
  };

  return (
    <div>
      <HeaderComp
        title={title}
        actions={actions}
        actionCallback={onActionClick}
        logo={<span className="logo-text">{t("READERS.TITLE_TEXT")}</span>}
      />
    </div>
  );
};
export default Header;
