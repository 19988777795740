import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
interface UpdateFirmwareResponse {
  id: string;
  updateFirmwareOverallStatus: string;
  updateFirmwarePhase1Status: string;
  updateFirmwarePhase2Status: string;
  updateFirmwarePhase3Status: string;
  fwUpdateProgressPercentage: number;
}
interface InitialStateDataProps {
  selectedreaders: any[];
  choosenReaders: { [correlationId: string]: any[] };
  selectedFirmware: string;
  isUpgradeFirmwareProgressModalOpen: boolean;
  updateFirmwareResponse: { [correlationId: string]: UpdateFirmwareResponse[] };
  latestFWVersion: string;
  availableFirmwareVersions: any;
}
const initialState: InitialStateDataProps = {
  selectedreaders: [],
  choosenReaders: {},
  selectedFirmware: "",
  isUpgradeFirmwareProgressModalOpen: false,
  updateFirmwareResponse: {
  },
  latestFWVersion: "",
  availableFirmwareVersions: {},
};

const upgradeFirmwareSlice = createSlice({
  name: "upgradeFirmware",
  initialState,
  reducers: {
    setSelectedReaders: (state, action: PayloadAction<any[]>) => {
      state.selectedreaders = action.payload;
    },
    setSelectedFirmware: (state, action: PayloadAction<string>) => {
      state.selectedFirmware = action.payload;
    },
    setUpgradeFirmwareProgressModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isUpgradeFirmwareProgressModalOpen = action.payload;
    },
    setChoosenReaders: (
      state,
      action: PayloadAction<{ [correlationId: string]: any[] }>
    ) => {
      state.choosenReaders = action.payload;
    },
    setUpdateFirmwareResponse: (
      state,
      action: PayloadAction<{
        updateFirmwareResponse: UpdateFirmwareResponse[];
        correlationId: string;
      }>
    ) => {
      const devices =
        state.updateFirmwareResponse[action.payload.correlationId] || [];
      action.payload.updateFirmwareResponse.forEach((data) => {
        const existingDevice = devices.find((device) => device.id === data.id);
        if (existingDevice) {
          devices[devices.indexOf(existingDevice)] = {
            ...existingDevice,
            ...data,
          };
        } else {
          devices.push(data);
        }
      });
      state.updateFirmwareResponse = {
        ...state.updateFirmwareResponse,
        [action.payload.correlationId]: devices,
      };
    },
    removeFIrmwareResponse: (state, action: PayloadAction<string>) => {
      delete state.updateFirmwareResponse[action.payload];
    },
    resetFWStateToDefault: (state) => {
      // Reset each field in the state to its default value
      state.selectedreaders = [];
      state.choosenReaders = {};
      state.selectedFirmware = "";
      state.isUpgradeFirmwareProgressModalOpen = false;
      state.updateFirmwareResponse = {};
    },
    setLatestFWVersion: (state, action) => {
      state.latestFWVersion = action.payload;
    },
    setAvailableFirmwareVersions: (state, action) => {
      state.availableFirmwareVersions = action.payload;
    },
  },
});

export default upgradeFirmwareSlice.reducer;
export const {
  setSelectedReaders,
  setSelectedFirmware,
  setUpgradeFirmwareProgressModalOpen,
  setUpdateFirmwareResponse,
  setChoosenReaders,
  resetFWStateToDefault,
  setLatestFWVersion,
  setAvailableFirmwareVersions,
  removeFIrmwareResponse,
} = upgradeFirmwareSlice.actions;
export const upgradeFirmwareData = (state: RootState) => state.upgradeFirmware;
