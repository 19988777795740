import {
  Button,
  Iconography,
  Typography,
  TypographyVariant,
} from "@hid-galaxy-ui/galaxy-react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";

export interface IChosenConfiguration {
  firmware: string;
  onUpdateClick: (flag: boolean) => void;
  isDisabled: boolean;
}
export default function ChosenConfiguration(props: IChosenConfiguration) {
  const { t } = useTranslation(NAMESPACE.READER);
  const { firmware, onUpdateClick, isDisabled } = props;
  const renderTitle = () => (
    <h2 className="hid-typography__h2">{t("READERS.CHOSEN_FIRMWARE_VERSION")}</h2>
  );
  return (
    <div className="reader-info">
      <div className="hid-border-bottom hid-layout__p-03 hid-layout__mb-03">
        {renderTitle()}
        <Typography
          variant={TypographyVariant.BodyShortProduct}
          className="hid-layout__mb-02 hid-layout__p-02"
        >
          <Iconography icon="arrowsRepeat" className="hid-layout__mr-01" />
          {firmware}
        </Typography>
      </div>
      <div className="reader-info__button">
        <Button
          label={t("READERS.UPDATE_FIRMWARE")}
          variant="primary"
          className="hid-layout__m-02 hid-layout__mb-04 hid-layout__pl-10 hid-layout__pr-10"
          disabled={!firmware}
          onClick={() => onUpdateClick(true)}
        />
      </div>
    </div>
  );
}
