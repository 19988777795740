import { Get, Post, getSessionId } from "../utils/apiServices";
import { ITOSResponse } from "../pages/login/termsOfService";

const loginEndpointPrefix = "/linq-cloud/login";
const login_endpoint = `${loginEndpointPrefix}/validate-user`;
const getTosEndpoint = "/linq-cloud/tos?service=LinqCloud";
const postTosEndpoint = "/linq-cloud/tos";
const logoutEndpoint = "/linq-cloud/logout";

export interface ILoginResponse {
  isSuccess: Boolean;
  token: string;
}
export interface ILoginRequest {
  userName: string;
  password: string;
}

export interface IUserEmail {
  email: string;
}

export interface IValidEmailResponse {
  encodedData: string;
}

export const doLogin = async (data: ILoginRequest) =>
  await Post<ILoginResponse, any>(login_endpoint, data);

export const termsOfService = async () =>
  await Get<ITOSResponse, any>(
    getTosEndpoint,
    {},
    {
      headers: {
        session: getSessionId(),
      },
    }
  );

export interface acceptTosPayload {
  email: string;
  version: number;
}
export const acceptTermsofService = async (
  payload: acceptTosPayload
) => {
  return await Post<any, acceptTosPayload>(postTosEndpoint, payload, {
    headers: {
      session: getSessionId(),
    },
  });
};
export const callLogoutService = async () => {
  return await Post<any, undefined>(logoutEndpoint, undefined, {
    headers: {
      session: getSessionId(),
    },
  });
};