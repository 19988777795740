import {
  Card,
  Iconography,
  IconographySizes,
  IconographyThemes,
  InlineStatus,
  InlineStatusAppearance,
  InlineStatusIconType,
  Pagination,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import readerIcon from "../../assets/icons/reader.svg";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { applyTemplateResultsPageSize } from "../../../../utils/commonConst";
import { useEffect, useState } from "react";

interface IFailed {
  data: { id: string; applyDcidStatus: string; dcid?: string }[];
}
export default function Failed(props: IFailed) {
  const { data } = props;
  const { t } = useTranslation(NAMESPACE.READER);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [tableData, setTableData]=useState<any>()
  useEffect(() => {
    if (applyTemplateResultsPageSize) {
      const pageData = data?.slice(
        (currentPageNumber - 1) * applyTemplateResultsPageSize,
        (currentPageNumber - 1) * applyTemplateResultsPageSize +
        applyTemplateResultsPageSize
      );
      setTableData(pageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, data]);
  const onPageNumberClick = (pageNumber: number | null) => {
    pageNumber && setCurrentPageNumber(pageNumber);
  };

  return (
    <div className="hid-spacing__mb-05">
      {data?.length > 0 ? (
        <div className="hid-flex hid-flex-jc-between danger-box">
          <div className="hid-flex hid-flex-align-center">
              <Iconography icon="circleX" size={IconographySizes.Small} theme={IconographyThemes.Error}></Iconography>
            <Typography
              variant={TypographyVariantEnum.BodyShortProduct}
              className="hid-spacing__ml-02"
            >
              {t("READERS.FOLLOWING_READERS_ARE_FAILED")}
            </Typography>
            {/* <div>
              <Iconography icon="circleInfo" size={IconographySizes.Small}></Iconography>
            <Typography
              variant={TypographyVariantEnum.BodyShortProduct}
              className="hid-spacing__ml-02"
            >
              {t("READERS.READER_VERIFY")}
            </Typography>
            </div> */}
          </div>
        </div>
      ) : null}
      {tableData?.map(
        (reader: { id: string; applyDcidStatus: string; dcid?: string }) => (
          <Card>
            <div className="hid-flex">
              <img
                src={readerIcon}
                alt="reader-icon"
                className="hid-layout__mr-02"
                width={15}
              />
              <div style={{ flex: "auto" }} className="hid-layout__ml-03">
                <Typography variant={TypographyVariantEnum.Label}>
                  {reader.dcid}
                </Typography>
                <Typography variant={TypographyVariantEnum.BodyShortMarketing}>
                  {reader.id}
                </Typography>
              </div>
              <InlineStatus
                label=""
                appearance={InlineStatusAppearance.Danger}
                iconType={InlineStatusIconType.Icon}
              />
            </div>
          </Card>
        )
      )}
      {data?.length > 0 ? (
        <div className="hid-spacing__mt-05">
          <Pagination
            currentPageNumber={currentPageNumber || 0}
            totalRecords={data?.length}
            pageSize={applyTemplateResultsPageSize}
            onPageNumberClick={onPageNumberClick}
            border={true}
          />
        </div>
      ) : null}
    </div>
  );
}
