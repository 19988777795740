// ==========================================
//  Title:  Header Action
//  Author: Ben Franklin
//  Date:   9 july 2021
// ==========================================

import React from "react";
import { ActionItem } from "./interfaces";
import { Badge, ContextualMenu, Iconography, IconographySizes } from "@hid-galaxy-ui/galaxy-react";
import { getKey } from ".";

export function getActions(
  actions: Array<ActionItem>,
  actionCallback: (e: React.MouseEvent<HTMLElement>, key: string) => void,
  userName: string | undefined
) {
  const handleClick = (e: React.MouseEvent<HTMLElement>, key: string) => {
    if (actionCallback) actionCallback(e, key);
  };
  return (
    <ul className="hid-header__actions">
      {actions.length <= 5 &&
        actions.map((action: ActionItem, index) => {
          const { subNavItems, isUserAction } = action;
          return subNavItems?.length > 0 ? (
            <li
              key={getKey(subNavItems?.key, index)}
              className="hid-header__actions__list-item"
            >
              <ContextualMenu
                contextualMenuData={subNavItems}
                onClick={(e, item) => handleClick(e, item.key)}
                placement={"bottom-end"}
                isToogle={true}
              >
                <button className="hid-header__action">
                  <Iconography
                    icon={action.icon}
                    size={IconographySizes.Medium}
                  />
                  {isUserAction ? (
                    <>
                      <span className="hid-header__action__text">
                        {userName}
                      </span>
                      <Iconography icon="angleDown" />
                    </>
                  ) : null}
                </button>
              </ContextualMenu>
            </li>
          ) : (
            <li
              key={getKey(action.key, index)}
              className="hid-header__actions__list-item"
            >
              <button
                onClick={(e) => handleClick(e, action.key)}
                className="hid-header__action"
              >
                <Iconography
                  icon={action.icon}
                  size={IconographySizes.Medium}
                />
                {action.badge ? <Badge value={action.badge} /> : null}
              </button>
            </li>
          );
        })}
    </ul>
  );
}
