import { ApplyTemplate as SearchDCID } from "@hidglobal/search-dcid-components";
import {
  applyTemplateService,
  fetchDCIDService,
  searchDCIDService,
} from "../../../services/readerAPIService";
import ReaderSelectModal from "./readerSelectModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import {
  applyTemplateData,
  setFilterState,
  setIsProgressModalOpen,
  setRunningProcesses,
  setSearchTemplateData,
  setSearchTemplatePagination,
  setSelectedCorrelationId,
  setSelectedDCIDTemplate,
  setSelectedReaders,
} from "../../../reducers/applyTemplateReducer";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../../App";
import { IGateway } from "./types";
import {
  loginInfoData,
  setGlobalToasts,
} from "../../../reducers/userInfoReducer";
import {
  buildNotification,
  showDeviceBusyNotification,
} from "../../../utils/notification";
import { defaultPageSize } from "../../../config";
import Notification from "@hid-galaxy-ui/galaxy-react/components/Notification";
import ProtocolModal from "./ProtocolModal";

export interface IFilterObj {
  searchText?: string;
  status?: {
    label: string;
    value: string;
  };
  deviceType?: {
    label: string;
    value: string;
  };
  schowMyDCID?: boolean;
  searchById?: boolean;
}
export interface IResponse {
  comments: string;
  deviceConfigurationIdentifier: string;
  deviceType: string;
  name: string;
  orderable: boolean;
  parent: any;
  softwareVersions: string;
  status: string;
  modifiedTimestamp?: string;
  stored?: string;
}
export interface IPaginationData {
  id?: string;
  readersName?: string;
  status: string;
  template?: string;
  firmware?: string;
  lastUpdated: string;
  configName?: string;
  deviceType?: string;
  identifier?: string;
  stored?: string;
  description?: string;
}

const all_status = ["PUBLISHED", "PUBLISHABLE", "DRAFT", "DEACTIVATED"];
export default function ApplyTemplate() {
  const dispatch = useDispatch();
  const { t } = useTranslation(NAMESPACE.READER);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDCID, setSelectedDCID] = useState<any>();
  const [templateData, setTemplateData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [customHeader, setCustomHeader] = useState<string>("");
  const [showProtocolModal, setShowProtocolModal] = useState<any>({
    show: false,
    data: {},
    readers: [],
    content: "",
  });
  const { filterState, searchTemplateData, searchTemplatePagination } =
    useSelector(applyTemplateData);
  const [toasts, setToasts] = useState([]) as any;
  const [filterObj, setFilterObj] = useState<IFilterObj>(
    filterState
      ? filterState
      : {
          searchText: "",
          status: {
            label: "All Statuses",
            value: "ALL",
          },
          deviceType: {
            label: "All Device Types",
            value: "ALL",
          },
          schowMyDCID: true,
          searchById: true,
        }
  );
  const [currentPageSize, setCurrentPageSize] =
    useState<number>(defaultPageSize);
  const { selectedCustomerInfo, userInfo } = useSelector(loginInfoData);

  const customerId = selectedCustomerInfo?.customerId?.toString();
  useEffect(() => {
    if (searchTemplateData) {
      setTemplateData(searchTemplateData);
      searchTemplatePagination?.totalRecords &&
        setTotalRecords(
          handleTotalRecords(
            searchTemplatePagination.totalRecords,
            currentPageSize
          ) || 0
        );
      searchTemplatePagination?.pageSize &&
        setCurrentPageSize(searchTemplatePagination.pageSize);
      searchTemplatePagination?.currentPage &&
        setCurrentPage(searchTemplatePagination.currentPage);
    }
  }, []);
  useEffect(() => {
    dispatch(
      setSearchTemplatePagination({
        currentPage: currentPage,
        pageSize: currentPageSize,
        totalRecords: totalRecords,
      })
    );
  }, [currentPage, totalRecords, currentPageSize, dispatch]);

  const onApplyTemplate = (dcid: any) => {
    setSelectedDCID(dcid);
    setIsModalOpen(true);
  };
  const onApplyTemplateConfirm = async (
    data: IGateway,
    readers: any,
    restrictProtocol: boolean | string = true,
    restrictOsdpVersionChange: boolean | string = true
  ) => {
    try {
      const dataFields = {
        gatewayId: data.gateway,
      };
      const payload = {
        deviceConfigurationIdentifier: selectedDCID.identifier,
        // deviceConfigurationIdentifier: "007T7V",
        connectedDeviceId: readers.map((r: any) => r.id),
      };
      if (customerId) {
        const res = await applyTemplateService(
          dataFields,
          payload,
          customerId,
          restrictProtocol,
          restrictOsdpVersionChange
        );
        if (res?.status.code === 202) {
          setShowProtocolModal({
            show: false,
            data: {},
            readers: [],
            content: "",
          });
          const correlationId = res.data.correlationId;
          dispatch(setSelectedReaders({ [correlationId]: readers }));
          dispatch(
            setSelectedDCIDTemplate({
              [correlationId]: selectedDCID.identifier,
            })
          );
          connect(correlationId);
          dispatch(setSelectedCorrelationId(correlationId));
          dispatch(
            setRunningProcesses({
              [correlationId]: { isCompleted: false, type: "applyTemplate" },
            })
          );
          setIsModalOpen(false);
          dispatch(setIsProgressModalOpen(true));
        }
      }
    } catch (err: any) {
      if (err.response.status === 409) {
        if (
          err.response.data?.statusDescription.indexOf(
            "communication protocol"
          ) !== -1
        ) {
          setCustomHeader("restrictProtocolChange");

          setShowProtocolModal((prev: any) => {
            return {
              ...prev,
              show: true,
              data: data,
              readers: readers,
              content: err.response.data.statusDescription,
            };
          });
          return;
        }
        else if (
          err.response.data?.statusDescription.indexOf(
            "OSDP protocol version to V1"
          ) !== -1
        ) {
          setCustomHeader("restrictOsdpVersionChange");
          setShowProtocolModal((prev: any) => {
            return {
              ...prev,
              show: true,
              data: data,
              readers: readers,
              content: err.response.data.statusDescription,
            };
          });
          return;
        }

        showDeviceBusyNotification(
          dispatch,
          t("READERS.DEVICE_OPERATION_INPROGRESS")
        );
      } else {
        showDeviceBusyNotification(
          dispatch,
          err.response.data.statusDescription
        );
      }
    }
  };
  const buildFilterPayload = (filterObj: IFilterObj) => {
    return {
      metadata: {
        ...(filterObj.searchText && {
          name: filterObj.searchText,
          // comments: filterObj.searchText,
        }),
        ...(filterObj?.status?.value &&
          (filterObj.status.value === "ALL"
            ? {
                status: {
                  $in: all_status,
                },
              }
            : {
                status: {
                  $eq: filterObj.status.value,
                },
              })),
        ...(filterObj?.deviceType?.value &&
          filterObj?.deviceType?.value !== "ALL" && {
            deviceType: "HID_SIGNO_READER",
          }),
        ...(filterObj?.schowMyDCID && {
          createdBy: {
            $eq: userInfo?.email,
          },
        }),
      },
    };
  };

  const handleTotalRecords = (totalRecords: number, pageSize: any) => {
    switch (parseInt(pageSize)) {
      case 8:
        return totalRecords > 40000 ? 40000 : totalRecords;
      case 10:
        return totalRecords > 50000 ? 50000 : totalRecords;
      case 20:
        return totalRecords > 100000 ? 100000 : totalRecords;
      case 30:
        return totalRecords > 150000 ? 150000 : totalRecords;
      case 40:
        return totalRecords > 200000 ? 200000 : totalRecords;
      case 50:
        return totalRecords > 250000 ? 250000 : totalRecords;
    }
  };

  const getTemplateData = async (
    pageNumber: number,
    pageSize: number,
    payload?: any
  ) => {
    let resultObj = {} as any;
    try {
      if (payload.searchById && payload.searchText) {
        //Call fetch DCID API here
        const result = await fetchDCIDService(
          payload.searchText?.toUpperCase()
        );
        resultObj = result;
        if (result?.status?.code === 200) {
          const metaData = result.data.metadata;
          const templateData1 = {
            name: metaData?.name,
            deviceType: metaData?.deviceType,
            identifier: metaData.deviceConfigurationIdentifier,
            status: metaData.status,
            stored: metaData.stored,
            description: metaData.comments,
            lastUpdated: getProperDate(metaData.modifiedTimestamp || "") || "",
          };
          setTotalRecords(1);
          setTemplateData([templateData1]);
          dispatch(setSearchTemplateData([templateData1]));
        } else if (result.statusCode === 404) {
          dispatch(
            setGlobalToasts([
              buildNotification("Error", result.statusDescription),
            ])
          );
          setCurrentPageSize(defaultPageSize);
          setTotalRecords(0);
          setTemplateData([]);
          dispatch(setSearchTemplateData([]));
        }
      } else {
        const finalPayload = buildFilterPayload(payload);
        const sendData = {
          ...finalPayload,
          pageSize: pageSize,
          pageNumber: pageNumber,
        };
        const res = await searchDCIDService(sendData);
        setTotalRecords(
          handleTotalRecords(res.data.data.totalRecordCount, pageSize) || 0
        );
        setTemplateData(processedData(res.data.data.results));
        dispatch(setSearchTemplateData(processedData(res.data.data.results)));
        return res.data;
      }
    } catch (err: any) {
      const message = err?.response?.data?.statusDescription;
      setCurrentPageSize(defaultPageSize);
      setTotalRecords(0);
      setTemplateData([]);
      dispatch(setSearchTemplateData([]));
      setToasts([
        buildNotification(
          "Error",
          resultObj?.statusDescription || err?.response?.data?.statusDescription
        ),
      ]);
    }
  };
  const getProperDate = (dateString: string) => {
    const dateObject = new Date(dateString);

    const options = { month: undefined, day: undefined, year: undefined };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    const readableDate = formatter.format(dateObject);
    var inputDate = new Date(readableDate);

    // Get the day, month, and year from the Date object
    var day = inputDate.getDate();
    var monthIndex = inputDate.getMonth();
    var year = inputDate.getFullYear();

    // Create an array of month names
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Format the date in the desired format
    var formattedDate = day + " " + monthNames[monthIndex] + ", " + year;

    return formattedDate;
  };
  const processedData = (data: IResponse[]): IPaginationData[] => {
    return data.map((data: IResponse) => {
      return {
        name: data.name,
        deviceType: data.deviceType,
        identifier: data.deviceConfigurationIdentifier,
        status: data.status,
        stored: data.stored,
        description: data.comments,
        lastUpdated: getProperDate(data.modifiedTimestamp || "") || "",
      };
    });
  };

  const onSearch = async (payload: any) => {
    setFilterObj(payload);
    dispatch(setFilterState(payload));
    const containsSpecialCharacters =
      /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\s\|]+/.test(payload.searchText);
    if (
      !(
        payload?.searchById &&
        payload?.searchText &&
        (containsSpecialCharacters ||
          payload?.searchText.length < 6 ||
          payload?.searchText.length > 6)
      )
    ) {
      await getTemplateData(1, currentPageSize, payload);
      setCurrentPage(1);
    } else {
      setToasts([
        buildNotification("Error", t("READERS.INVALID_CONFIGURATION_ID")),
      ]);
    }
  };

  const handelPageClick = async (pageNumber: number) => {
    await getTemplateData(pageNumber, currentPageSize, filterObj);
    setCurrentPage(pageNumber);
  };

  const onPageSizeChange = async (pageSize: number) => {
    setCurrentPageSize(pageSize);

    await getTemplateData(1, pageSize, filterObj);
    setCurrentPage(1);
  };
  return (
    <>
      <SearchDCID
        isConectedToOrigo={true}
        onApplyTemplate={(templateDetails: any) =>
          onApplyTemplate(templateDetails)
        }
        onSelectReaderConfiguration={(configuration: any) => {
          console.log(configuration);
        }}
        handleOnSearchFilter={(filterDetails: any) => {
          onSearch(filterDetails);
        }}
        paginationConfig={{
          currentPageNumber: currentPage,
          totalRecords: totalRecords,
          pageSize: currentPageSize,
          onPageNumberClick: (pagenumber: number) => {
            handelPageClick(pagenumber);
          },
          onPageSizesClick: (pageSize: number) => {
            onPageSizeChange(pageSize);
          },
        }}
        handleOnContextualMenu={(data: any) => console.log(data)}
        data={templateData}
        filterState={filterObj}
      />
      {isModalOpen && (
        <ReaderSelectModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedDCID={selectedDCID}
          onApplyTemplateConfirm={onApplyTemplateConfirm}
        />
      )}
      {showProtocolModal.show && (
        <ProtocolModal
          onClose={() =>
            setShowProtocolModal({
              show: false,
              data: {},
              readers: [],
              content: "",
            })
          }
          title={t("READERS.APPLY_CONFIGURATION").toUpperCase()} 
          onContinue={() =>
            onApplyTemplateConfirm(
              showProtocolModal.data,
              showProtocolModal.readers,
              customHeader !== "restrictProtocolChange",
              customHeader !== "restrictOsdpVersionChange"
            )
          }
          content={showProtocolModal.content}
        />
      )}
      <Notification
        toasts={toasts}
        setToasts={setToasts}
        isSticky={false}
        isAutoClose={true}
      />
    </>
  );
}
