import { Button, Iconography, Modal, Typography } from "@hid-galaxy-ui/galaxy-react";
import { ModalVariants } from "@hid-galaxy-ui/galaxy-react/components/Modal/modalEnums";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useSelector } from "react-redux";
import { upgradeFirmwareData } from "../../reducers/upgradeFirmwareReducer";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";

export default function FirmwareCheckModal(props: { onClose: () => void }) {
  const { onClose } = props;
  const { t } = useTranslation(NAMESPACE.READER);
  const { selectedreaders } = useSelector(upgradeFirmwareData);
  const uniqueFwDisplayNames = new Set(
    selectedreaders.map((device) => device.fwDisplayName)
  );
  return (
    <Modal size={ModalVariants.Medium} onClose={onClose}>
      <div className="hid-grid hid-grid--center-xs hid-spacing__p-07">
        <div className="hid-grid__column--10-xs hid-text-center">
          <Typography variant={TypographyVariantEnum.H1}>
          {t("READERS.READER_WITH_MULTIPLE_FIRMWARE")}
          </Typography>
        </div>
        <Typography variant={TypographyVariantEnum.BodyLongMarketing} className="hid-spacing__mt-05">
        {t("READERS.DIFFERENT_FIRMWARE_ERROR")}
        </Typography>
        <div className="hid-flex hid-flex-jc-between error-box">
          <div className="hid-flex">
            <Iconography icon="arrowsRepeat" className="red-color" />
            <Typography
              variant={TypographyVariantEnum.Label}
              className={"hid-spacing__ml-05"}
            >
              {`${uniqueFwDisplayNames.size} ${t("READERS.DIFFERENT_FIRMWARE")}`}
            </Typography>
          </div>
        </div>
        <div className="hid-flex hid-flex-jc-center ">
          <Button
            variant="primary"
            onClick={onClose}
            label={t("READERS.BACK_AND_SELECT_READER")}
          />
        </div>
      </div>
    </Modal>
  );
}
