import {
  Button,
  DataTable,
  Pagination,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { ICoulmnData, ITableData } from "../../readers";
import { useDispatch } from "react-redux";
import { isVentoReader } from "../../../../utils/linq_utils";
interface IPagination {
  currentPageNumber: number;
  totalRecords: number;
  pageSize: number;
}
interface IReaderTableProps {
  data: {
    id: string;
    fwDisplayName: string;
    dcid: string;
    readerType: string;
    controllerName: string;
    lastUpdatedDate: string;
  }[];
  columnsData: ICoulmnData[];
  dependencies?: {
    handleActionClick: (rowData: ITableData) => void;
  };
  onSelectReaders?: (data: IReadersData[]) => void;
  selectedreaders?: IReadersData[];
  timestamp?: string | null;
  fetchReaders?: () => {};
}
interface IReadersData {
  id: string;
  fwDisplayName?: string;
  dcid?: string;
  readerType?: string;
  controllerName?: string;
  lastUpdatedDate?: string;
  operationAllowed?: boolean;
  fwUpdateRequired?: boolean;
}

export default function ReadersTable(props: IReaderTableProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const {
    data,
    columnsData,
    dependencies,
    onSelectReaders,
    selectedreaders = [],
    timestamp,
    fetchReaders,
  } = props;
  const [selectedRows, setSelectedRows] =
    useState<IReadersData[]>(selectedreaders);
  const [paginationConfig, setPaginationConfig] = useState<IPagination>({
    currentPageNumber: 1,
    totalRecords: data?.length || 0,
    pageSize: 8,
  });
  const [tableData, setTableData] = useState<IReadersData[]>([]);

  useEffect(() => {
    const updateDData = data.map((data: IReadersData) => {
      return { ...data, operationAllowed: !data.operationAllowed ||data.fwUpdateRequired || isVentoReader(data.readerType||"") };
    });
    if (paginationConfig.pageSize) {
      const pageData = updateDData.slice(
        (paginationConfig.currentPageNumber - 1) * paginationConfig.pageSize,
        (paginationConfig.currentPageNumber - 1) * paginationConfig.pageSize +
          paginationConfig.pageSize
      );
      setTableData(pageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig,data]);
  
  const onPageNumberClick = (pageNumber: any) => {
    setPaginationConfig({ ...paginationConfig, currentPageNumber: pageNumber });
  };
  const rowSelection: {
    selectedRowKeys: string[];
    onSelect: (selectedRowKeys: any, currentRow: any, selected: any) => void;
    onSelectAll: (selectedRowKeys: any) => void;
  } = {
    selectedRowKeys: selectedreaders?.map((c) => c.id),
    onSelect: (selectedRowKeys, currentRow, selected) => {
      if (selected) {
        const isExist = selectedRows.find(
          (c: IReadersData) => c.id === currentRow.id
        );
        if (!isExist) {
          setSelectedRows([...selectedRows, currentRow]);
        }
      } else {
        const updatedRows = selectedRows.filter(
          (c: IReadersData) => c.id !== currentRow.id
        );
        setSelectedRows(updatedRows);
      }
    },
    onSelectAll: (selectedRowKeys) => {
      if (selectedRowKeys.length > selectedRows.length) {
        const selectedReaders = tableData.filter((c) =>
          selectedRowKeys.includes(c.id)
        );

        setSelectedRows([...selectedRows, ...selectedReaders]);
      } else {
        const selectedReaders = selectedRows.filter((c) =>
          selectedRowKeys.includes(c.id)
        );
        setSelectedRows(selectedReaders);
      }
    },
  };
  const getDiscoveredTime = () => {
    if (timestamp) {
      let date = new Date(parseInt(timestamp));

      // Get hours and minutes
      let hours = date.getHours();
      let minutes = date.getMinutes();
      // Format hours and minutes with leading zeros
      let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      return formattedTime;
    }
  };
  return (
    <div className="hid-origo__reader-layout hid-spacing__mt-03" data-testid="readers-table">
      {/* <div className="hid-flex hid-flex-jc-between hid-spacing__mb-04"> */}
      {/* <ContentSwitch
          data={[
            { key: "card", label: t("READERS.CARD_VIEW") },
            { key: "list", label: t("READERS.LIST_VIEW") },
          ]}
          selected="list"
        /> */}
      <div className="hid-flex hid-flex-ai-center hid-flex-jc-between hid-spacing__mb-04">
        <Typography
          variant={TypographyVariantEnum.BodyLongProduct}
          className={"hid-spacing__mr-2"}
        >
          {`
            ${selectedRows?.length} ${t("READERS.READERS_SELECTED")}`} 
        </Typography>
        <Button variant="primary" label="Discover" onClick={() => fetchReaders?.()}/>
        <Typography
          variant={TypographyVariantEnum.BodyLongProduct}
          className={"hid-spacing__mr-2 discovered-text"}
        >
          {`Discovered : ${getDiscoveredTime()}`}
        </Typography>
        <Button
          label= {t("READERS.APPLY")}
          className={"hid-spacing__ml-10"}
          variant="primary"
          disabled={!selectedRows.length}
          onClick={() => onSelectReaders && onSelectReaders(selectedRows)}
        />
      </div>
      {/* </div> */}

      <DataTable
        dataKey={"id"}
        isSelectable={tableData.length > 0 ? true : false}
        data={tableData}
        columns={columnsData}
        rowSelectionConfig={rowSelection}
        dependencies={dependencies}
        emptyHandlerInfo={{
          isEmpty: tableData.length === 0,
          renderError: () => (
            <span>{t("READERS.NO_READER_CONNECTED_ERROR")}</span>
          ),
        }}
        rowDisableKey="operationAllowed"
      />
      {tableData.length > 0 ? (
        <Pagination
          currentPageNumber={paginationConfig.currentPageNumber || 0}
          totalRecords={data?.length }
          pageSize={paginationConfig.pageSize}
          onPageNumberClick={onPageNumberClick}
          border={true}
        />
      ) : null}
    </div>
  );
}
