import {
  Button,
  Divider,
  Iconography,
  Loader,
  LoaderSizes,
  Modal,
  TextField,
  Toggle,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { ModalVariants } from "@hid-galaxy-ui/galaxy-react/components/Modal/modalEnums";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { IConfigModalData } from "..";
import { NAMESPACE } from "../../../../utils/i18nUtils";

interface IConfigProps {
  setConfigModalData: Dispatch<SetStateAction<IConfigModalData>>;
  configModalData: IConfigModalData;
}

export default function ConfigTemplateModal(props: IConfigProps) {
  const { setConfigModalData, configModalData } = props;
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as any;
    setConfigModalData({
      ...configModalData,
      [target.name]: target.value,
    });
  };
  const { t } = useTranslation(NAMESPACE.READER);
  return (
    <Modal
      size={ModalVariants.Medium}
      headerText=""
      isDisplayCloseIcon={true}
      onClose={() =>
        setConfigModalData({ ...configModalData, openModal: false })
      }
    >
      <div className="hid-layout__px-05 ">
        <Typography variant={TypographyVariantEnum.ProfileHeader}>
          {t("READERS.NEW_CONFIGURATION")}
        </Typography>
        <Typography
          className="hid-origo__config-p-color"
          variant={TypographyVariantEnum.BodyShortProduct}
        >
          {t("READERS.GIVE_A_NAME")}
        </Typography>
        <div className="hid-flex hid-flex-jc-between hid-layout__mt-04">
          <Typography variant={TypographyVariantEnum.BodyShortProduct}>
            {t("READERS.ONLY_NAME")}
            <span className="hid-origo__required">{t("READERS.REQUIRED")}</span>
          </Typography>
          <div>{configModalData.configName.length}/250</div>
        </div>
        <TextField
          id="configName"
          name="configName"
          placeholder={t("READERS.READER_CONFIGURATION_NAME")}
          value={configModalData.configName}
          theme="dark"
          onChange={handleOnChange}
          inputProps={{ maxLength: "250" }}
        />
        <div className="hid-flex hid-flex-jc-between hid-layout__mt-04">
          <Typography variant={TypographyVariantEnum.BodyShortProduct}>
            {t("READERS.COMMENT")}
          </Typography>
          <div>{configModalData.comment.length}/250</div>
        </div>
        <div className="hid-origo__comment-field">
          <TextField
            id="comment"
            name="comment"
            value={configModalData.comment}
            theme="dark"
            onChange={handleOnChange}
            inputProps={{ maxLength: "250" }}
          />
        </div>
        <div className="hid-layout__mt-04">
          <Divider />
        </div>
        <div className="hid-flex  hid-layout__mt-04">
          <Toggle
            onClick={() =>
              setConfigModalData({
                ...configModalData,
                orderableToggle: !configModalData.orderableToggle,
              })
            }
            toggled={configModalData.orderableToggle}
          />
          <Typography
            className="hid-origo__linq-reader-specs-top-margin "
            variant={TypographyVariantEnum.Label}
          >
            {t("READERS.MAKE_CONFIGURATION_ORDERABLE")}
          </Typography>
        </div>
        <TextField
          id="parentIdentifier"
          name="parentIdentifier"
          label="Parent Configuration Template"
          placeholder={t("READERS.PARENT_IDENTIFIER")}
          value={configModalData?.parentIdentifier}
          theme="dark"
          onChange={handleOnChange}
        />
        {/* <div className="hid-flex hid-flex-ai-center">
          <Loader variant={LoaderSizes.small} />
          <Typography
            className="hid-layout__ml-01"
            variant={TypographyVariantEnum.TextHelper}
          >
            {t("READERS.CHECKING_CONFIGURATION")}
          </Typography>
          <Iconography icon="circleCheck" theme={IconographyThemes.Success} />
          <Typography
            className="hid-layout__ml-01"
            variant={TypographyVariantEnum.TextHelper}
          >
            {t("READERS.VALID_CONFIGURATION")}
          </Typography>
        </div> */}
        <div className="hid-flex hid-flex-jc-between hid-layout__mt-06">
          <Button label={t("READERS.CANCEL")} variant="secondary" onClick={()=>setConfigModalData({...configModalData,openModal:false})} />
          <Button label={t("READERS.CREATE_NEW")} variant="primary" disabled={!configModalData.configName} />
        </div>
      </div>
    </Modal>
  );
}
