export const privacyLink = "https://www.hidglobal.com/about/privacy";
export const termsOfUseLink = "https://www.hidglobal.com/about/terms-of-use";
export const hidSupportLink = "https://www.hidglobal.com/support";
export const naoPortalUrl =
  "https://p1.location-services.preprod.webportal.origo.hidcloud.com";

export const pageSize = 6;

export const passwordMinLength = 10;

export const passwordMaxLength = 100;

export const oneMBinKB = 1048576;

export const gatewayDeviceInstaller="https://linq-cloud-reader-gateway-dev.s3.amazonaws.com/hid-client-reader-bridge.exe"

export const linqOnpremInstaller="https://temp-linq.system.auth.api.origo.hidcloud.com/resources/initial/setup.exe"

export const defaultPageSize = 10;