import React, { ReactNode } from "react";
import { Button, Iconography, IconographySizes, IconographyThemes, Modal, Typography, TypographyVariant } from "@hid-galaxy-ui/galaxy-react";
import { NAMESPACE, getCommonNS } from "../../utils/i18nUtils";
import { useTranslation } from "react-i18next";

interface IMultipleSessionModal {
  closeModal: (value: boolean) => void
}

export default function MultipleSessionModal(props: IMultipleSessionModal) {
  const { t } = useTranslation(NAMESPACE.COMMON);  

  return (
    <>
      <Modal
          isDisplayCloseIcon={false}
          isFocusTrapActive={false}
          width={748}
          className="hid-modal__custom-footer"
        >
          <header className="hid-linq__modal-header hid-spacing__mt-07">
            <div className="hid-modal__header-headline hid-text-center">
              <Typography variant={TypographyVariant.ProfileHeader}>
                {t("COMMON.MULTIPLE_ACTIVE_SESSION_TITLE", getCommonNS())}
              </Typography>
            </div>

          </header>
          <div className="hid-text-center hid-layout__mt-04">
            <Iconography
              theme={IconographyThemes.Error}
              size={IconographySizes.Large}
              icon="clock"
              style={{ width: '62px', height: '62px' }}
            />
            <Typography
              className="hid-layout__px-05 hid-text-center hid-spacing__mt-06 hid-spacing__mb-06"
              variant={TypographyVariant.BodyLongMarketing}
            >
              {t("COMMON.MULTIPLE_ACTIVE_SESSION_MSG", getCommonNS())}

            </Typography>

            <Button
              type="button"
              variant="primary"
              id="submitShortcutModal"
              name={t("COMMON.CONTINUE", getCommonNS())}
              label={t("COMMON.CONTINUE", getCommonNS())}
              onClick={() => props.closeModal(false)}
            />
          </div>
        </Modal>
    </>
  );
}