import { Loader } from "@hid-galaxy-ui/galaxy-react";
import { LoaderSizes } from "@hid-galaxy-ui/galaxy-react/components/Loader/loaderEnums";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LogoutInterceptor from "./LogoutInterceptor";
import RouteComponent from "./RouteComponent";
import {
  addRequestEndListener,
  addRequestStartListener,
  showNotificationListener,
} from "./utils/apiServices";
import Notification from "@hid-galaxy-ui/galaxy-react/components/Notification";
import { IRouteConfig, routesConfig } from "./utils/routes";
import { isUserLoggedIn } from "./utils/linq_utils";
import {
  applyTemplateData,
  setApplyTemplateResponse,
  setResetReaderResponse,
  setRunningProcesses,
} from "./reducers/applyTemplateReducer";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import "./utils/SocketInit";
import { connectToStomp, disconnect, unSubscribe } from "./utils/SocketInit";
import ApplyTemplateNotification from "./widgets/ApplyTemplateNotification";
import { loginInfoData, setGlobalToasts } from "./reducers/userInfoReducer";
import {
  setSelectedReaders,
  setUpdateFirmwareResponse,
  upgradeFirmwareData,
} from "./reducers/upgradeFirmwareReducer";
import { buildNotification } from "./utils/notification";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "./utils/i18nUtils";
import { reEmmitApiCallback } from "./services/readerAPIService";
import store from "./store/store";
import { getNextGroupName } from "./utils/commonConst";
export { connectToStomp, disconnect };

const callReemmitCallback = (id: string, pendingReq: any[]): boolean => {
  const callbackid = pendingReq.find((item) => item.requestId == id);
  return !!callbackid;
};

let customDispatch: Dispatch;
export const connect = async (
  correlationId: string,
  from: string = "applyTemplate",
  customer_id?: string
) => {
  try {
    const { loginInfo } = store.getState();
    const customerId =
      customer_id || loginInfo.selectedCustomerInfo?.customerId;
    const email = loginInfo.userInfo?.email;
    const pendingReq = loginInfo.pendingRequests;
    await connectToStomp((stompClient: any) => {
      stompClient.subscribe(
        `/topic/dcid-apply/${correlationId}`,
        function (response: any) {
          if (response.body) {
            if (from === "applyTemplate") {
              if (
                JSON.parse(response.body).status ===
                "DEVICE_APPLY_CONFIG_FAILED"
              ) {
                customDispatch(
                  setRunningProcesses({
                    [correlationId]: {
                      isCompleted: true,
                      type: "applyTemplate",
                      deviceDisconnected: true,
                    },
                  })
                );
                return;
              }
              customDispatch(
                setApplyTemplateResponse({
                  [correlationId]: JSON.parse(response.body).deviceDetails,
                  correlationId: correlationId,
                })
              );
              console.log("store data",store.getState());
            } else if (from === "resetReader") {
              let deviceDetails: any = [];
              deviceDetails[correlationId] = JSON.parse(
                response.body
              ).deviceDetails;
              if (
                JSON.parse(response.body).status ===
                "DEVICE_APPLY_CONFIG_FAILED"
              ) {
                customDispatch(
                  setRunningProcesses({
                    [correlationId]: {
                      isCompleted: true,
                      type: "reset",
                      deviceDisconnected: true,
                    },
                  })
                );
                return;
              }
              customDispatch(setResetReaderResponse(deviceDetails));
              customDispatch(
                setRunningProcesses({
                  [correlationId]: { isCompleted: true, type: "reset" },
                })
              );
            }
          }
        },
        { id: correlationId }
      );
    });
    setTimeout(() => {
      callReemmitCallback(correlationId, pendingReq) &&
        reEmmitApiCallback({
          customerId: customerId!.toString() || "",
          email: email || "",
        });
    }, 5000);
  } catch (err) {
    console.log(err);
  }
};
export const connectToUpgradeFirmware = async (
  correlationId: string,
  customer_Id?: string
) => {
  const { loginInfo, applyTemplate } = store.getState();
  const { runningProcesses } = applyTemplate;
  const customerId = customer_Id || loginInfo.selectedCustomerInfo?.customerId;
  const email = loginInfo.userInfo?.email;
  const pendingReq = loginInfo.pendingRequests;
  await connectToStomp((stompClient: any) => {
    stompClient.subscribe(
      `/topic/update-firmware/${correlationId}`,
      function (response: any) {
        if (response.body) {
          const responseData = JSON.parse(response.body).deviceDetails;
          if (
            JSON.parse(response.body).status === "DEVICE_FW_UPGRADE_FAILED" ||
            JSON.parse(response.body).status === "DEVICE_FW_UPGRADE_SYNC_FAILED"
          ) {
            customDispatch(
              setRunningProcesses({
                [correlationId]: {
                  ...(runningProcesses && {
                    ...runningProcesses[correlationId],
                  }),
                  isCompleted: true,
                  type: "upgrade",
                  deviceDisconnected: true,
                },
              })
            );
            return;
          }
          customDispatch(
            setUpdateFirmwareResponse({
              updateFirmwareResponse: responseData,
              correlationId: correlationId,
            })
          );

          const isCompleted =
            responseData.filter(
              (d: any) =>
                d.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_QUEUED" ||
                d.updateFirmwareOverallStatus ===
                  "DEVICE_FW_UPGRADE_IN_PROGRESS"
            ).length === 0;
          if (isCompleted) {
            customDispatch(
              setRunningProcesses({
                [correlationId]: {
                  ...(runningProcesses && {
                    ...runningProcesses[correlationId],
                  }),
                  isCompleted: isCompleted,
                  type: "upgrade",
                },
              })
            );
            unSubscribe(correlationId);
          }
        }
      },
      { id: correlationId }
    );
  });
  setTimeout(() => {
    callReemmitCallback(correlationId, pendingReq) &&
      reEmmitApiCallback({
        customerId: customerId?.toString() || "",
        email: email || "",
      });
  }, 5000);
};

function App() {
  const [showLoader, setShowLoader] = useState(false);
  const isAuthenticated = isUserLoggedIn();
  const { t } = useTranslation(NAMESPACE.READER);
  const { runningProcesses } = useSelector(applyTemplateData);
  const { selectedCustomerInfo } = useSelector(loginInfoData);
  customDispatch = useDispatch();
  useEffect(() => {
    addRequestStartListener(() => {
      setShowLoader(true);
    });
    addRequestEndListener(() => {
      setShowLoader(false);
    });
    showNotificationListener(() => {
      customDispatch(
        setGlobalToasts([
          buildNotification("Error", t("READERS.REQUEST_FAILED")),
        ])
      );
    });
    const isPageRefreshed = localStorage.getItem("isPageRefreshed");
    if (isPageRefreshed) {
      // Perform the action you want after the page has been refreshed
      console.log("Page was refreshed");
      for (const key in runningProcesses) {
        if (runningProcesses.hasOwnProperty(key)) {
          const item = runningProcesses[key];
          const customer_id = selectedCustomerInfo?.customerId.toString() || "";
          const correlationId = key;
          const requestType = item.type;
          if (requestType === "upgrade") {
            // customDispatch(setUpgradeFirmwareProgressModalOpen(true));
            connectToUpgradeFirmware(correlationId, customer_id);
          } else if (requestType === "reset") {
            // dispatch(setIsResetReaderProgressModalOpen(true))
            // setSelectedCorrelationId
            connect(correlationId, "resetReader");
          } else {
            connect(correlationId, "applyTemplate", customer_id);
          }
        }
      }

      // Clear the flag after detecting the refresh
      localStorage.removeItem("isPageRefreshed");
    }
    // Set the flag to detect refresh
    localStorage.setItem("isPageRefreshed", "true");

    return () => {
      // Clean up the flag when the component unmounts
      localStorage.removeItem("isPageRefreshed");
    };
  }, []);
  const { globalToasts } = useSelector(loginInfoData);
  const setToasts = (
    data: {
      id: string;
    }[]
  ) => {
    customDispatch(setGlobalToasts(data));
  };
  return (
    <>
      {isAuthenticated && (
        <Notification
          toasts={globalToasts}
          setToasts={setToasts}
          isSticky={false}
          isAutoClose={true}
        />
      )}
      <Router>
        <Routes>
          {routesConfig.map((route: IRouteConfig, index: number) => (
            <Route
              path={route.path}
              key={`Route_${index}`}
              element={
                <RouteComponent
                  allowedRole={route.allowedRoles}
                  isProtected={route.isProtected}
                  layout={route.layout}
                  component={route.component}
                />
              }
            />
          ))}
        </Routes>
        <LogoutInterceptor />
      </Router>
      {showLoader && <Loader variant={LoaderSizes.large} isOverlay />}
      {isAuthenticated && <ApplyTemplateNotification />}
    </>
  );
}

export default App;
