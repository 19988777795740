import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginPath } from "../../utils/routes";

const UnauthorizedRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(loginPath);
  }, []);

  return null;
};

export default UnauthorizedRedirect;
