import {
  Avatar,
  AvatarSize,
  Card,
  Iconography,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";

interface IOrganizationCard {
  name: string;
  onClick?: (data: { customerId: number; customerName: string }) => void;
  id: number;
  selected?: boolean;
}

export default function OrganizationCard({
  name,
  onClick,
  id,
  selected,
}: IOrganizationCard) {
  return (
    <div className="hid-layout__mt-04">
      <Card
        selected={selected}
        variant="selectable"
        cardSelectedIcon={null}
        onClick={() =>
          onClick && onClick({ customerId: id, customerName: name })
        }
      >
        <div className="hid-grid">
          <div className="hid-grid__column hid-grid__column--2-xs ">
            <Avatar
              size={AvatarSize.Large}
              editorProps={{
                icon: "building",
              }}
            />
          </div>
          <div className="hid-grid__column hid-grid__column--9-xs hid-flex hid-flex-ai-center ">
            <Typography variant={TypographyVariantEnum.BodyShortMarketing}>
              {name}
            </Typography>
          </div>
          <div
            className="hid-grid__column hid-grid__column--1-xs hid-flex hid-flex-ai-center "
            style={{ cursor: "pointer" }}
          >
            <Iconography icon="angleRight" />
          </div>
        </div>
      </Card>
    </div>
  );
}
