import { useEffect, useRef, useState, useCallback } from 'react';
import SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { socketUrl } from './apiServices';

export const useStompClient = () => {
  const stompClientRef = useRef<Stomp.Client | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [subscriptions, setSubscriptions] = useState<{ [key: string]: Stomp.Subscription }>({});
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const maxReconnectAttempts = 10; // Maximum reconnect attempts
  const reconnectDelay = 2000; // Initial reconnect delay in milliseconds

  const connectToStomp = useCallback((onConnect: () => void) => {
    if (stompClientRef.current && stompClientRef.current.connected) {
      onConnect();
      return;
    }
    const socket = new SockJS(socketUrl);
    const stompClient = Stomp.over(socket);
    stompClient.connect(
      {},
      () => {
        stompClientRef.current = stompClient;
        setIsConnected(true);
        setReconnectAttempts(0); // Reset reconnect attempts on successful connection
        onConnect();
      },
      (error) => {
        console.error('STOMP connection error:', error);
        setIsConnected(false);
        handleReconnect(onConnect);
      }
    );
  }, []);

  const handleReconnect = useCallback(
    (onConnect: () => void) => {
      if (reconnectAttempts < maxReconnectAttempts) {
        const delay = reconnectDelay * Math.pow(2, reconnectAttempts); // Exponential backoff
        setTimeout(() => {
          console.log(`Attempting to reconnect... Attempt #${reconnectAttempts + 1}`);
          setReconnectAttempts((prev) => prev + 1);
          connectToStomp(onConnect);
        }, delay);
      } else {
        console.error('Max reconnect attempts reached. Could not reconnect to STOMP server.');
      }
    },
    [reconnectAttempts, connectToStomp]
  );

  const disconnect = useCallback(() => {
    stompClientRef.current?.disconnect(() => {
      setIsConnected(false);
      stompClientRef.current = null;
    });
  }, []);

  const subscribe = useCallback(
    (topic: string, callback: (message: Stomp.Message) => void, id: string) => {
      if (!stompClientRef.current) return;
      const subscription = stompClientRef.current.subscribe(topic, callback, { id });
      setSubscriptions((subs) => ({ ...subs, [id]: subscription }));
    },
    []
  );

  const unsubscribe = useCallback(
    (id: string) => {
      subscriptions[id]?.unsubscribe();
      setSubscriptions((subs) => {
        const { [id]: _, ...rest } = subs;
        return rest;
      });
    },
    [subscriptions]
  );

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  return {
    isConnected,
    connectToStomp,
    disconnect,
    subscribe,
    unsubscribe,
  };
};
