import { TemplateSummary } from "@hidglobal/template-editor-components";
import {
  createDcidPath,
  templateEditorPath,
} from "../../../../../utils/routes";
import { NAMESPACE } from "../../../../../utils/i18nUtils";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../../../widgets/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { temlateCreateInfoData } from "../../../../../reducers/templateCreationReducer";
import {
  loginInfoData,
  setGlobalToasts,
} from "../../../../../reducers/userInfoReducer";
import { saveToDraftService } from "../../../../../services/readerAPIService";
import { buildNotification } from "../../../../../utils/notification";
export default function ConfigurationSummary() {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const { templateData } = useSelector(temlateCreateInfoData);
  const { selectedCustomerInfo } = useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  const saveToDraft = async (data: any) => {
    try {
      const payload = {
        baseConfiguration: templateData,
        overrideConfiguration: null,
        completeOverrideConfiguration: false,
        metadata: {
          deviceType: "HID_SIGNO_READER_HWCNG", //TODO remove hardcode
          softwareVersion: "R10.0.5.8",  //TODO remove hardcode
          parent: null,
          effectiveCommonParent: null,
          internalComments: null,
          licenseApplicable: "00",
          orderable: false,
          status: "DRAFT",
          customerId: customerId,

        },
      };
      const res = await saveToDraftService(payload);
      dispatch(
        setGlobalToasts([
          buildNotification(t("READERS.STATUS_UPDATED"), "Success"),
        ])
      );
    } catch (err) {
      dispatch(setGlobalToasts([buildNotification("Error", "Error")]));
    }
  };
  const getBreadCrumbsData = () => {
    return [
      {
        name: t("READERS.DEVICE_CONFIGURATIONS"),
        href: createDcidPath,
        isCurrentPage: false,
      },
      {
        name: t("READERS.START_NEW_CONFIGURATION"),
        href: createDcidPath,
        isCurrentPage: false,
      },
      {
        name: t("READERS.CONFIGURATION_EDITOR"),
        href: templateEditorPath,
        isCurrentPage: false,
      },
      {
        name: t("READERS.CONFIGURATION_SUMMARY"),
        href: "#",
        isCurrentPage: true,
      },
    ];
  };

  const onNameSave = (name: string) => {
  };

  return (
    <>
        <div className="hid-template-editor">
        <div className="hid-layout__px-07 hid-layout__py-03">
          <BreadCrumbs data={getBreadCrumbsData()} />
        </div>
        <div>
          <TemplateSummary
            templateStatus={"Unsaved"}
            // onSaveToDraft={saveToDraft}
            onNameSave={onNameSave}
          />
        </div>
        </div>
    </>
  );
}
