import {
  Button,
  Modal,
  ModalVariants,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import "./updateFirmwareModal.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { upgradeFirmwareData } from "../../../../reducers/upgradeFirmwareReducer";
import ContentSwitch from "@hid-galaxy-ui/galaxy-react/components/ContentSwitcher";
import ReaderList from "./ReaderList";
import { applyTemplateData } from "../../../../reducers/applyTemplateReducer";
import ProgressPhases from "./progressPhases";
import loadingRepeat from "../../../../assets/svgs/loading-repeat.svg";
import loadingline from "../../../../assets/svgs/loading-line-1.svg";
import { unSubscribe } from "../../../../utils/SocketInit";
export enum UpdateFirmwarePhases {
  PHASE1 = "Phase1",
  PHASE2 = "Phase2",
  SUCCESS = "Success",
  FAILURE = "Failure",
}
export enum Tabview {
  UPDATING = "Updating",
  COMPLETED = "Completed",
  FAILED = "Failed",
}
export default function UpdateFirmwareProgress(props: { onClose: () => void }) {
  const { t } = useTranslation(NAMESPACE.READER);
  const [view, setView] = useState<Tabview>(Tabview.UPDATING);
  const { selectedCorrelationId } = useSelector(applyTemplateData);
  const { selectedFirmware, updateFirmwareResponse } =
    useSelector(upgradeFirmwareData);

  // const UpdateFirmwareData =
  //   updateFirmwareResponse[selectedCorrelationId] || [];
    const readerList:any = [];

for (const key in updateFirmwareResponse) {
  updateFirmwareResponse[key].forEach(item => {
    readerList.push(item);
  });
}
  useEffect(() => {
    if (upgradeFirmwareData.length === selectedFirmware.length) {
      if (
        readerList.filter(
          (data:any) =>
            data.updateFirmwareOverallStatus ===
              "DEVICE_FW_UPGRADE_IN_PROGRESS" ||
            data.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_QUEUED"
        ).length === 0
      ) {
        // unSubscribe(selectedCorrelationId);
      }
    }
  }, [updateFirmwareResponse]);
  const readerInUpdate = readerList?.filter(
    (data:any) =>
      data.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_IN_PROGRESS" ||
      data.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_QUEUED"
  );
  const readersCompleted = readerList?.filter(
    (data:any) => data.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SUCCESS"
  );
  const readersFailed = readerList.filter(
    (data:any) =>
      data.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SYNC_FAILED" ||
      data.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_FAILED"
  );
  const readersInQueue = readerList?.filter(
    (data:any) => data.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_QUEUED"
  );

  const renderView = () => {
    switch (view) {
      case Tabview.UPDATING:
        return (
          <ReaderList
          readers={readerInUpdate}
          stage={view}
          firmware={selectedFirmware}
          showProgress
        />
        );

      case Tabview.FAILED:
        return (
          <ReaderList
            readers={readersFailed}
            stage={view}
            firmware={selectedFirmware}
          />
        );
      case Tabview.COMPLETED:
        return (
          <ReaderList
            readers={readersCompleted}
            stage={view}
            firmware={selectedFirmware}
          />
        );
    }
  };
  return (
    <Modal
      size={ModalVariants.Large}
      isFocusTrapActive={false}
      isDisplayCloseIcon={true}
      onClose={props.onClose}
    >
      <div className="hid-grid hid-grid--center-xs">
        <div className="hid-grid__column hid-grid__column--12-xs hid-flex hid-flex-jc-between ">
          <div className="update-firmware__title hid-spacing__mb-05">
            <Typography variant={TypographyVariantEnum.ProfileHeader}>
              {t("READERS.UPDATE_FIRMWARE")}
            </Typography>
          </div>
        </div>
        <div className="hid-grid__column hid-grid__column--12-xs hid-flex hid-flex-jc-center ">
          <ContentSwitch
            data={[
              { key: Tabview.UPDATING, label: t("READERS.UPDATING") },
              {
                key: Tabview.COMPLETED,
                label: `${t("READERS.COMPLETED")}${
                  readersCompleted.length || 0
                })`,
              },
              {
                key: Tabview.FAILED,
                label: `${t("READERS.FAILED")}${readersFailed.length || 0})`,
              },
            ]}
            selected={view}
            onChange={(_: any, key: string) => setView(key as Tabview)}
          />
        </div>
        <div className="w-90 reader-list">{renderView()}</div>
        <div className="hid-flex hid-flex-jc-center hid-spacing__mt-08">
          <Button
            variant="primary"
            onClick={props.onClose}
            label={t("READERS.CLOSE")}
          />
        </div>
      </div>
    </Modal>
  );
}
