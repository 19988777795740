import {
  Button,
  Modal,
  ModalVariants,
  TextArea,
  TextField,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { useTranslation } from "react-i18next";
import { getCommonNS, NAMESPACE } from "../../../../utils/i18nUtils";
import Device from "../../../../assets/images/device.png";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useState } from "react";
import { alphanumWithUnderscoreAndHyphen } from "../../../../utils/regexExpressions";

interface IEditReaderProps {
  setModalOpen: (e: React.MouseEvent<HTMLElement>) => void;
  readerName: string;
  description: string;
  onSave: (data: { deviceName: string }) => void;
}

interface IUserInfo {
  readerName: string;
  description?: string;
}

export default function EditReader(props: IEditReaderProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const initialValues = {
    readerName: props.readerName,
    description: props.description,
  };
  const { onSave } = props;
  const [readerInfo, setReaderInfo] = useState<IUserInfo>(initialValues);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as any;
    if (alphanumWithUnderscoreAndHyphen.test(value) && value.length <= 50) {
      setReaderInfo({ ...readerInfo, [name]: value });
    }
  };

  const saveReader = () => {
    const payload = {
      deviceName: readerInfo.readerName,
    };
    onSave(payload);
  };
  return (
    <Modal
      size={ModalVariants.Medium}
      onClose={props.setModalOpen}
      isFocusTrapActive={false}
    >
      <>
        <img
          src={Device}
          className="hid-origo__linq-reader-image"
          alt=""
          data-testid="reader-img"
        />
        <Typography
          className="hid-text-center"
          variant={TypographyVariantEnum.H2}
        >
          { readerInfo.readerName ||""}
        </Typography>

        <div
          className="hid-layout__mt-05 hid-spacing__mb-03"
          data-testid="reader-name"
        >
          <TextField
            label={t("READERS.READER_NAME")}
            placeholder={t("READERS.NAME")}
            id="readerName"
            name="readerName"
            value={readerInfo.readerName}
            maxLength={50}
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleChange(e)}
          />
        </div>

        <div className="hid-grid hid-spacing__mt-03">
          <div className="hid-grid__column hid-grid__column--6-xs">
            <Button
              label={t("COMMON.CANCEL", getCommonNS())}
              variant="secondary"
              className="hid-origo__delete-button"
              onClick={props.setModalOpen}
            />
          </div>
          <div className="hid-grid__column hid-grid__column--6-xs hid-text-right">
            <Button
              className=""
              label={t("COMMON.SAVE", getCommonNS())}
              variant="primary"
              onClick={saveReader}
            />
          </div>
        </div>
      </>
    </Modal>
  );
}
