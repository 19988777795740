import {
  Button,
  Divider,
  TextArea,
  TextField,
  Typography,
  TypographyVariant,
} from "@hid-galaxy-ui/galaxy-react";
import { DividerVariants } from "@hid-galaxy-ui/galaxy-react/components/Divider/dividerEnums";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCommonNS, NAMESPACE } from "../../../../../../utils/i18nUtils";
import { IDevices } from "../../../downloadGateway/types";
import StatusBox, { StatusTypes } from "../../../../../../widgets/StatusBox";
import blueGateway from "../../../../../../assets/svgs/gatewayWithBlueBackground.svg";
import { alphanumWithUnderscoreAndHyphen } from "../../../../../../utils/regexExpressions";
import { readerFriendlyNameLimit } from "../../../../../../utils/commonConst";

interface IGeneralInfoProps {
  selectedDevice: IDevices;
  onSave: (data: IDeviceInfo) => void;
}

export interface IDeviceInfo {
  name: string;
  description: string;
}

export default function GeneralInfo(props: IGeneralInfoProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const { selectedDevice, onSave } = props;
  const initialValues = {
    name: selectedDevice.name,
    description: selectedDevice.description,
  };
  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfo>(initialValues);

  const allowReaderFriendlyNameAsPerLimit = (val:string) => {
    return val?.trim()?.length <= readerFriendlyNameLimit
  }

  const handleDeviceChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as any;
    if (alphanumWithUnderscoreAndHyphen.test(value) && allowReaderFriendlyNameAsPerLimit(value)) {
      setDeviceInfo({ ...deviceInfo, [name]: value });
    }
  };
  return (
    <div className="hid-spacing__p-04 hid-spacing__pt-01">
      <Typography
        variant={TypographyVariant.ProfileHeader}
        className="hid-text-center"
      >
        Change Gateway Name
      </Typography>
      <StatusBox
        message={
          <div className="hid-spacing__mt-03">
            <Typography variant={TypographyVariant.H3}>
              {t("READERS.GIVE_FRIENDLY_NAME")}
            </Typography>
            <Typography variant={TypographyVariant.BodyShortProduct}>
              {t("READERS.GIVE_FRIENDLY_NAME_DESC")}
            </Typography>
          </div>
        }
        icon={<img src={blueGateway} alt="gateway" />}
        type={StatusTypes.blank}
      />

      <div className="hid-layout__mt-07">
        <Divider mode={DividerVariants.Content} />
      </div>

      <div className="hid-layout__mt-05 hid-spacing__mb-05">
        <TextField
          label={t("READERS.GATEWAY_NAME")}
          placeholder={t("READERS.GATEWAY_NAME")}
          id="gateWayName"
          name="name"
          value={deviceInfo.name}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleDeviceChange(e)
          }
          disabled={deviceInfo?.name?.trim().length > readerFriendlyNameLimit}
        />
      </div>

      <TextArea
        className="hid-layout__mt-03 hid-spacing__mt-05"
        allowResize={true}
        label={t("READERS.DESCRIPTION")}
        name="description"
        id="description"
        value={deviceInfo.description}
        placeholder="Placeholder Text"
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleDeviceChange(e)
        }
      />

      <div className="hid-grid hid-layout__mt-05">
        <div className="hid-grid__column  hid-text-right">
          <Button
            label={t("COMMON.SAVE", getCommonNS())}
            variant="primary"
            onClick={() => onSave(deviceInfo)}
            disabled={
              (deviceInfo.name.trim().length === 0) || 
              (deviceInfo.name === selectedDevice.name &&
              deviceInfo.description === selectedDevice.description)
            }
          />
        </div>
      </div>
    </div>
  );
}
