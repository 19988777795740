import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userInfo from "../reducers/userInfoReducer";
import applyTemplate from "../reducers/applyTemplateReducer";
import upgradeFirmware from "../reducers/upgradeFirmwareReducer";
import templateCreateInfo from "../reducers/templateCreationReducer";
import { persistReducer } from "redux-persist";
import discoverReaders from "../reducers/discoverReadersReducer";
import storage from "redux-persist/lib/storage";
const persistConfigLogin = {
  key: "userInfo",
  storage,
  blacklist: ["extras"],
};
const persistConfigApplyTemplate = {
  key: "applyTemplate",
  storage,
  blacklist: ["notification"],
};
const persistConfigUpdateFirmware = {
  key: "updateFirmware",
  storage,
  blacklist: ["notification"],
};
const persistTemplateCraete = {
  key: "templateCreateInfo",
  storage,
  blacklist: ["notification"],
};
const persistConfigDiscoveredReaders = {
  key: "discoverReaders",
  storage,
};
const rootReducer = combineReducers({
  loginInfo: persistReducer(persistConfigLogin, userInfo),
  applyTemplate: persistReducer(persistConfigApplyTemplate, applyTemplate),
  upgradeFirmware: persistReducer(persistConfigUpdateFirmware, upgradeFirmware),
  templateCreate: persistReducer(persistTemplateCraete, templateCreateInfo),
  discoveredReaders:persistReducer(persistConfigDiscoveredReaders,discoverReaders),
  //add more reducer here
});
const store = configureStore({
  reducer: rootReducer,
});


export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


