import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import readerIcon from "../../assets/icons/reader.svg";
import { Tabview } from "./updateFirmwareProgress";
import {
  Card,
  Iconography,
  IconographyThemes,
  InlineStatus,
  InlineStatusAppearance,
  InlineStatusIconType,
  Loader,
  LoaderSizes,
  Pagination,
  ProgressBar,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useEffect, useState } from "react";

interface IReaderList {
  readers: any[];
  stage: Tabview;
  firmware: string;
  showProgress?: boolean;
}
const PageSize = 4;

export default function ReaderList(props: IReaderList) {
  const { readers = [], stage, firmware, showProgress } = props;

  const { t } = useTranslation(NAMESPACE.READER);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [displayedReaders, setDisplayedReaders] = useState<any[]>([]);
  useEffect(() => {
    const startIndex = (currentPageNumber - 1) * PageSize;
    const endIndex = startIndex + PageSize;
    const displayedReaders = readers.slice(startIndex, endIndex);
    setDisplayedReaders(displayedReaders);
  }, [readers,currentPageNumber]);
  const statusMapper = {
    [Tabview.UPDATING]: (
      <>
        <div className="hid-flex updating-content">
          <Typography variant={TypographyVariantEnum.H3}>
            {`${t("READERS.UPDATING")}:`}
          </Typography>
          &nbsp;
          <Typography
            variant={TypographyVariantEnum.BodyShortMarketing}
            className="color-default"
          >
            {t("READERS.IN_PROGRESS")}
          </Typography>
        </div>
      </>
    ),
    [Tabview.COMPLETED]: (
      <InlineStatus
        appearance={InlineStatusAppearance.Positive}
        label={t("READERS.UPDATE_SUCCESSFUL")}
        iconType={InlineStatusIconType.Icon}
      />
    ),
    [Tabview.FAILED]: (
      <span className="red-text">{t("READERS.UPDATE_FAILED")}</span>
    ),
  };
  const onPageNumberClick = (pageNumber: number | null) => {
    pageNumber && setCurrentPageNumber(pageNumber);
  };
  return (
    <>
      {displayedReaders.map((reader: any, index) => (
        <Card className="hid-spacing__m-03" key={index}>
          <>
            <div className="hid-flex  hid-spacing__p-05">
              <img
                src={readerIcon}
                alt="reader-icon"
                className="hid-layout__mr-02"
                width={15}
              />
              <div style={{ flex: "auto" }} className="hid-layout__ml-03">
                {reader.firmwareUpdateRequired && stage === Tabview.FAILED ? (
                  <InlineStatus
                    appearance={InlineStatusAppearance.Caution}
                    label={t("READERS.FW_UPDATE_REQUIRED_MODAL")}
                    iconType={InlineStatusIconType.Icon}
                  />
                ) : (
                  statusMapper[stage]
                )}

                <Typography variant={TypographyVariantEnum.BodyShortMarketing}>
                  {reader.id}
                </Typography>
              </div>
              {stage === Tabview.UPDATING && (
                <div
                  className="hid-flex hid-flex-ai-center hid-spacing__ml-10"
                  style={{ flex: "1", justifyContent: "center" }}
                >
                  <Loader variant={LoaderSizes.small} />
                  &nbsp;&nbsp;
                  {firmware}
                </div>
              )}
              {stage === Tabview.FAILED && (
                <div
                  className="hid-flex hid-flex-ai-center hid-spacing__ml-10"
                  style={{ flex: "1", justifyContent: "center", alignItems: "center" }}
                >
                  <Iconography
                    icon="circleExclamation"
                    theme={IconographyThemes.Error}
                  />
                  &nbsp;&nbsp;
                  {firmware}
                </div>
              )}
            </div>
            {showProgress && (
              <ProgressBar progress={reader?.fwUpdateProgressPercentage ||0 } />
            )}
          </>
        </Card>
      ))}
      {readers.length > PageSize ? (
        <div className="hid-spacing__mt-05">
          <Pagination
            currentPageNumber={currentPageNumber || 0}
            totalRecords={readers?.length}
            pageSize={PageSize}
            onPageNumberClick={onPageNumberClick}
            border={true}
          />
        </div>
      ) : null}
    </>
  );
}
