import {
  Button,
  Iconography,
  Modal,
  ModalVariants,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import StatusBox, { StatusTypes } from "../../../../../widgets/StatusBox";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import "./TemplateEditor.scss";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../../utils/i18nUtils";

export default function CancelModal(props: {
  onClose: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation(NAMESPACE.READER);
  const { onClose, onCancel } = props;
  return (
    <Modal size={ModalVariants.Medium} onClose={onClose}>
      <Typography
        className="hid-flex hid-flex-jc-center hid-spacing__mb-05"
        variant={TypographyVariantEnum.H1}
      >
        {t("READERS.CANCEL_CONFIGURATION")}
      </Typography>
      <StatusBox
        type={StatusTypes.error}
        message={t("READERS.CANCEL_UNSAVED_CONFIGURATION")}
        icon={<Iconography icon="xmark" />}
      />
      <div className="hid-flex hid-flex-jc-end hid-spacing__mt-05">
        <Button
          variant="flat"
          label="Back to configuration editor"
          className="text-primary"
          onClick={onCancel}
        />
        <Button variant="warning" label="Cancel" onClick={onClose} />
      </div>
    </Modal>
  );
}
