import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosBasicCredentials,
} from "axios";

export interface IRequestOptions {
  headers?: any;
  basicAuth?: AxiosBasicCredentials;
}
const urlEnum = {
  DEV: "linqcloud.dev.origo.hidcloud.com",
  TEST: "linqcloud.test.origo.hidcloud.com",
  CERT: "linqcloud.cert.origo.hidglobal.com",
  PREPROD: "linqcloud.preprod.origo.hidglobal.com",
  PROD: "linqcloud.origo.hidglobal.com",
  LOCAL: "localhost:3000",
};

const urlMapper = {
  [urlEnum.DEV]: "https://linqcloud.dev.origo.hidcloud.com/origo/middleware",

  [urlEnum.TEST]: "https://linqcloud.test.origo.hidcloud.com/origo/middleware",

  [urlEnum.CERT]: "https://linqcloud.cert.origo.hidglobal.com/origo/middleware",

  [urlEnum.PREPROD]:
    "https://linqcloud.preprod.origo.hidglobal.com/origo/middleware",

  [urlEnum.PROD]: "https://linqcloud.origo.hidglobal.com/origo/middleware",

  [urlEnum.LOCAL]: "http://localhost:3000/origo/middleware",
};

const linqGatwayInstallerMapper = {
  [urlEnum.DEV]:
    "https://linqcloud.dev.origo.hidcloud.com/linq-installer/HID+Global+Linq+Gateway.exe",
  [urlEnum.TEST]:
    "https://linqcloud.test.origo.hidcloud.com/linq-installer/HID+Global+Linq+Gateway.exe",
  [urlEnum.CERT]:
    "https://linqcloud.cert.origo.hidglobal.com/linq-installer/HID+Global+Linq+Gateway.exe",
  [urlEnum.PREPROD]:
    "https://linqcloud.preprod.origo.hidglobal.com/linq-installer/HID+Global+Linq+Gateway.exe",
  [urlEnum.PROD]:
    "https://linqcloud.origo.hidglobal.com/linq-installer/HID+Global+Linq+Gateway.exe",
  [urlEnum.LOCAL]:
    "https://linqcloud.origo.hidglobal.com/linq-installer/HID+Global+Linq+Gateway.exe",
};
export const linqGatwayInstaller =
  linqGatwayInstallerMapper[window.location.host];
export const baseUrl = urlMapper[window.location.host];

console.log("base URL", baseUrl, window.location.host);
export const socketUrl = `${baseUrl}/linq-cloud/login/device-websocket`;
export const applyConfigSocketUrl = `${baseUrl}/linq-cloud/login/callback/linq-device-callback`;
// export const socketUrl = `https://linqcloud.preprod.origo.hidglobal.com/origo/middleware/linq-cloud/login/device-websocket`;
// export const applyConfigSocketUrl = `https://linqcloud.preprod.origo.hidglobal.com/origo/middleware/linq-cloud/login/callback/linq-device-callback`;

export const getSessionId = () => {
  const sessionId = localStorage.getItem("origo-session");
  if (sessionId) return "origo-session=" + sessionId;
};

const onRequest = (config: AxiosRequestConfig<any>): any => {
  if (config && !config.url!.includes("token")) {
    config.headers!.Authorization = "Bearer " + localStorage.getItem("token");
  }
  config.withCredentials = false;
  return config;
};
const onResponseSuccess = (response: any) => {
  endRequest();
  return response;
};

const onResponseError = (err: any) => {
  endRequest();
  if (err?.response?.status === 401) {
    localStorage.clear();
    logout();
  }
  if (err?.response?.status === 500) {
    showNotification?.();
  }
  return Promise.reject(err);
};

export const axiosInstance = axios.create({
  baseURL: baseUrl,

  timeout: 1000 * 60 * 5,
  validateStatus: function (status) {
    return status === 200 || status === 201 || status === 202 || status === 204;
  },
});

axiosInstance.interceptors.request.use(onRequest);
axiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);
axios.defaults.withCredentials = false;
let onRequestStart: () => void;
let onRequestEnd: () => void;
let onLogout: () => void;
let showNotification: () => void;
let totalRequests = 0;
let completedRequests = 0;
let isShowingLoader = false;

const startRequest = (displayLoader: boolean) => {
  if (displayLoader && !isShowingLoader) {
    onRequestStart?.();
    isShowingLoader = true;
  }
  totalRequests += 1;
};

const endRequest = () => {
  completedRequests += 1;
  if (completedRequests === totalRequests) {
    onRequestEnd?.();
    isShowingLoader = false;
  }
};

const logout = () => {
  onLogout?.();
};
export function showNotificationListener(callback: () => void) {
  showNotification = callback;
}
export function addLogoutListener(callback: () => void) {
  onLogout = callback;
}
export function addRequestStartListener(callback: () => void) {
  onRequestStart = callback;
}
export function addRequestEndListener(callback: () => void) {
  onRequestEnd = callback;
}

export async function Get<T, D>(
  endPoint: string,
  params: D,
  requestOptions: IRequestOptions = {},
  displayLoader: boolean = true
) {
  startRequest(displayLoader);
  const res = await axiosInstance.get<T, AxiosResponse<T>, D>(endPoint, {
    data: params,
    headers: requestOptions.headers,
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  });
  return res.data;
}

export async function Post<T, D>(
  endPoint: string,
  data: D,
  requestOptions: IRequestOptions = {},
  displayLoader: boolean = true,
  params = {}
) {
  startRequest(displayLoader);
  const res = await axiosInstance.post<T, AxiosResponse<T>, D>(endPoint, data, {
    params: params,
    headers: requestOptions.headers || {},
    auth: requestOptions.basicAuth,
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  });
  return res;
}
export async function Patch<T, D>(
  endPoint: string,
  data: D,
  requestOptions: IRequestOptions = {},
  displayLoader: boolean = true,
  params = {}
) {
  startRequest(displayLoader);
  const res = await axiosInstance.patch<T, AxiosResponse<T>, D>(
    endPoint,
    data,
    {
      params: params,
      headers: requestOptions.headers || {},
      auth: requestOptions.basicAuth,
      validateStatus: (status: number) =>
        (status >= 200 && status < 300) || status === 404,
    }
  );
  return res;
}

export async function Put<T, D>(
  endPoint: string,
  data: D,
  requestOptions: IRequestOptions = {},
  displayLoader: boolean = true
) {
  startRequest(displayLoader);
  const res = await axiosInstance.put<T, AxiosResponse<T>, D>(endPoint, data, {
    headers: requestOptions.headers,
  });
  return res.data;
}

export async function Delete<T>(
  endPoint: string,
  requestOptions: IRequestOptions = {},
  displayLoader: boolean = true,
  params = {}
) {
  startRequest(displayLoader);
  const res = await axiosInstance.delete<T>(endPoint, {
    params: params,
    headers: requestOptions.headers,
  });
  return res.data;
}

export async function FileDownload<T, D>(
  endPoint: string,
  params: D,
  requestOptions: IRequestOptions = {},
  displayLoader: boolean = true
) {
  startRequest(displayLoader);
  const res = await axiosInstance.get<T, AxiosResponse<T>, D>(endPoint, {
    data: params,
    headers: requestOptions.headers,
    responseType: "blob",
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  });
  return res;
}
