// ==========================================
//  Title:  Header
//  Author: Ben Franklin
//  Date:   9 july 2021
// ==========================================

import React from "react";
import classNames from "classnames";
import { HeaderProps } from "./interfaces";
import { getActions } from "./HeaderActions";
import HeaderNav from "./HeaderNav";
import { AsyncDropdown, Logo, SearchInput } from "@hid-galaxy-ui/galaxy-react";

export const getKey = (
  key: string | number | undefined | null,
  index: number
) => {
  return key ? key : index;
};
export default function Header(props: HeaderProps) {
  const {
    originHref = "/",
    title,
    actions,
    navItems,
    actionCallback,
    className,
    navitemCallback,
    id,
    searchConfig,
    userName,
    logo,
  } = props;
  const {
    allowSearch,
    isAsyncSearch = false,
    loadOptions,
    onSearch,
    selected,
    onSelect,
    ...otherSearchProps
  } = searchConfig || {};
  const renderSearchInput = () => {
    return isAsyncSearch ? (
      <AsyncDropdown
        id="search"
        loadOptions={loadOptions}
        onSelect={(value) => onSelect && onSelect(value)}
        isClearable={true}
        selected={selected}
        isSearchIcon={true}
      />
    ) : (
      <SearchInput
        isDisplayClearIcon={true}
        id="search"
        name="search"
        onSearch={(e, value) => onSearch && onSearch(e, value)}
        {...otherSearchProps}
      />
    );
  };
  return (
    <header
      className={classNames("hid-header", className)}
      {...(id && { id: id })}
    >
      <a href={"#"} className="hid-header__logo">
        {logo ? logo : <Logo theme="light" {...(title && { title: title })} />}
      </a>
      {navItems && (
        <HeaderNav
          navItems={navItems}
          navitemCallback={navitemCallback}
        ></HeaderNav>
      )}
      {allowSearch && (
        <div className="hid-header__search-wrapper hid-layout__mr-04">
          {renderSearchInput()}
        </div>
      )}

      {actions && getActions(actions, actionCallback, userName)}
    </header>
  );
}
