import {
  Button,
  Iconography,
  IconographySizes,
  IconographyThemes,
  InlineStatus,
  InlineStatusAppearance,
  InlineStatusIconType,
  Modal,
  ModalVariants,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import ContentSwitch from "@hid-galaxy-ui/galaxy-react/components/ContentSwitcher";
import DCIDIcon from "../../../../assets/svgs/dcid_icon.svg";
import readerProgress from "../../../../assets/svgs/readersProgress.svg";
import lightening from "../../../../assets/svgs/lightening.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  applyTemplateData,
  setRunningProcesses,
} from "../../../../reducers/applyTemplateReducer";
import { useEffect, useState } from "react";
import Completed from "./completed";
import Failed from "./failed";
import { unSubscribe } from "../../../../utils/SocketInit";

interface IApplyTemplateProgress {
  isOpen: boolean;
  onClose?: () => void;
}

export enum ApplyTemplateStatus {
  SUCCESS = "DEVICE_APPLY_CONFIG_SUCCESS",
  FAILURE = "DEVICE_APPLY_CONFIG_FAILED",
}
export default function ApplyTemplateProgress(props: IApplyTemplateProgress) {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const {
    applyTemplateResponse,
    selectedCorrelationId,
    selectedReaders,
    selectedDCID,
    runningProcesses = {},
  } = useSelector(applyTemplateData);
  const { isOpen, onClose } = props;
  const [view, setView] = useState<string>("appplying");
  const [showStatus, setShowStatus] = useState<boolean>(true);
  const currentResponse = applyTemplateResponse[selectedCorrelationId];
  const isCompleted =
    (runningProcesses &&
      runningProcesses[selectedCorrelationId]?.isCompleted) ||
    false;
  const getCompletedReaders = () => {
    return currentResponse?.filter(
      (r: any) => r.applyDcidStatus === ApplyTemplateStatus.SUCCESS
    );
  };
  const getFailedReaders = () => {
    return currentResponse?.filter(
      (r: any) => r.applyDcidStatus === ApplyTemplateStatus.FAILURE
    );
  };
  const readers = selectedReaders[selectedCorrelationId];
  useEffect(() => {
    if (currentResponse?.length >= readers?.length) {
      unSubscribe(selectedCorrelationId);
      dispatch(
        setRunningProcesses({
          [selectedCorrelationId]: { isCompleted: true, type: "applyTemplate" },
        })
      );
    }
  }, [currentResponse]);
  return isOpen ? (
    <Modal
      size={ModalVariants.Large}
      onClose={onClose}
      isDisplayCloseIcon={false}
    >
      <>
        <div className="hid-flex hid-flex-jc-center hid-spacing__pb-04 hid-spacing__pt-04">
          <Typography
            variant={TypographyVariantEnum.H1}
            className={"hid-spacing__mr-2"}
          >
            {t("READERS.APPLYING_CONFIGURATION_TEMPLATE")}
          </Typography>
        </div>
        <div className="hid-flex hid-flex-jc-center hid-spacing__pt-4">
          <ContentSwitch
            data={[
              { key: "appplying", label: t("READERS.APPLYING") },
              {
                key: "completed",
                label: `${t("READERS.COMPLETED")}${
                  getCompletedReaders()?.length || 0
                })`,
              },
              {
                key: "failed",
                label: `${t("READERS.FAILED")}${
                  getFailedReaders()?.length || 0
                })`,
              },
            ]}
            selected="appplying"
            onChange={(_: any, key: string) => setView(key)}
          />
        </div>
        {view === "appplying" ? (
          <>
            <div className="hid-grid hid-grid--center-xs hid-spacing__mb-03">
              <div className="hid-grid__column hid-grid__column--5-xs hid-grid__column--4-lg hid-hid-grid--center-xs hid-origo__mt-10">
                <div className="hid-origo__grey-card hid-layout__mt-04">
                  <div className="hid-grid">
                    <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                      <img src={DCIDIcon} alt="dcid-icon" />
                    </div>
                    <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                      {t("READERS.SELECTED_CONFIGURATIONID")}
                    </div>
                    <div className="hid-grid__column hid-grid__column--12-xs">
                      <span className="hid-origo__fs-32 hid-origo__bold-text">
                        {selectedDCID[selectedCorrelationId] ||
                          selectedReaders?.[selectedCorrelationId]?.dcid}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hid-grid__column hid-grid__column--7-xs hid-origo__mt-10">
                <div className="hid-origo__right-card hid-layout__mt-04">
                  <div className="hid-grid">
                    <div className="hid-grid__column hid-grid__column--10-xs ">
                      <div className="hid-grid">
                        <div className="hid-grid__column hid-grid__column--8-xs hid-origo__flex-center">
                          <div className="hid-grid hid-origo__flex-center">
                            {isCompleted ? (
                              <>
                                <div className="hid-grid__column hid-grid__column--12-xs">
                                  {t("READERS.OPERATION_COMPLETED")}
                                </div>
                                <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                                  <div className=" hid-spacing__mt-02">
                                    <Iconography
                                      icon="circleCheck"
                                      size={IconographySizes.Medium}
                                      className="hid-spacing__mr-02 hid-linq__icon-circleCheck"
                                    />
                                    {getCompletedReaders()?.length || 0}{" "}
                                    {t("READERS.READER(S)_TEXT")}
                                  </div>
                                  <div className="hid-spacing__mt-02">
                                    <Iconography
                                      icon="circleXmark"
                                      size={IconographySizes.Medium}
                                      className="hid-spacing__mr-02 hid-linq__icon-circleXmark"
                                    />
                                    {getFailedReaders()?.length || 0}{" "}
                                    {t("READERS.READER(S)_TEXT")}
                                  </div>
                                  {getFailedReaders()?.length ? (
                                    <div className="hid-spacing__mt-02 hid-flex">
                                      <div>
                                        <Iconography
                                          className="hid-spacing__mr-02"
                                          icon="circleInfo"
                                          size={IconographySizes.Medium}
                                          theme={IconographyThemes.Info}
                                        ></Iconography>{" "}
                                      </div>
                                      <div>{t("READERS.READER_VERIFY")}</div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="hid-grid__column hid-grid__column--12-xs">
                                  <Iconography
                                    icon="arrowRightToBracket"
                                    size={IconographySizes.Medium}
                                  />
                                </div>
                                <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10 ">
                                  {t("READERS.CURRENTLY_APPLYING_TO")}
                                </div>
                                <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                                  <span className="hid-origo__bold-text">
                                    {readers?.length || readers?.readersCount
                                      ? `${
                                          readers?.length ||
                                          readers?.readersCount
                                        } ${t("READERS.READERS")}`
                                      : null}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="hid-grid__column hid-grid__column--4-xs hid-origo__mt-10 hid-origo__flex-center">
                          <img src={readerProgress} alt="reader-progress" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showStatus && (
              <div className="hid-flex hid-flex-jc-between warning-box">
                <div className="hid-flex">
                  <img src={lightening} alt="lightening" />
                  <Typography
                    variant={TypographyVariantEnum.Label}
                    className={"hid-spacing__ml-05"}
                  >
                    {t("READERS.DONT_DISCONNECT")}
                  </Typography>
                </div>
                <Iconography
                  icon="xmark"
                  onClick={() => setShowStatus(false)}
                />
              </div>
            )}
          </>
        ) : view === "completed" ? (
          <Completed data={getCompletedReaders()} />
        ) : (
          <Failed data={getFailedReaders()} />
        )}

        <div className="hid-flex hid-flex-jc-center ">
          <Button
            variant="primary"
            onClick={onClose}
            label={t("READERS.CLOSE")}
          />
        </div>
      </>
    </Modal>
  ) : null;
}
