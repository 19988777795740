import { Iconography } from "@hid-galaxy-ui/galaxy-react";
import {
  IconographySizes,
  IconographyThemes,
} from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { linqGatwayInstaller } from "../../../../utils/apiServices";

interface IDownloadSuccess {
  onDownloadClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function DownloadSuccess(props: IDownloadSuccess) {
  const { onDownloadClick } = props;
  const { t } = useTranslation(NAMESPACE.READER);
  return (
    <div className="download-success hid-layout__p-04">
      <p className="download-success__title ">{t("READERS.THANK_YOU")}</p>
      <Iconography
        icon="circleCheck"
        size={IconographySizes.XLarge}
        theme={IconographyThemes.Success}
      />
      <p className="download-success__message hid-layout__mb-04">
        {t("READERS.DOWNLOAD_CONFIRMATION_MSG")}
      </p>
      <a
        href={linqGatwayInstaller}
        download
        onClick={onDownloadClick}
        className="no-underline hid-layout__mt-03 download-success__message-download"
      >
        {t("READERS.DOWNLOAD_FILE")}
      </a>
    </div>
  );
}