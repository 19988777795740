import {
  Button,
  Typography,
  Modal,
  ModalVariants,
} from "@hid-galaxy-ui/galaxy-react";
import DCIDIcon from "../../../../assets/svgs/dcid_icon.svg";
import ReaderGreenIcon from "../../../../assets/svgs/reader_green_icon.svg";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";

interface IReviewResetModal {
  onClose: () => void;
  onReset: () => void;
}
export default function ReviewResetModal(props: IReviewResetModal) {
  const { t } = useTranslation(NAMESPACE.READER);
  const { onClose, onReset } = props;

  return (
    <Modal
      size={ModalVariants.Large}
      onClose={onClose}
      className="reader-select-modal"
    >
      <div className="hid-flex hid-flex-jc-center hid-spacing__mb-6">
        <Typography
          variant={TypographyVariantEnum.H1}
          className={"hid-spacing__mr-2"}
        >
          {t("READERS.RESET_CONFIGURATION")}
        </Typography>
      </div>
      <div className="hid-grid hid-grid--center-xs">
        <div className="hid-grid__column hid-grid__column--4-xs hid-grid__column--2-lg hid-hid-grid--center-xs hid-origo__mt-10">
          <div className="hid-origo__grey-card hid-layout__mt-04">
            <div className="hid-grid">
              <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                <img src={DCIDIcon} alt="reader-icon" />
              </div>
              <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                {t("READERS.SELECTED_CONFIGURATIONID")}
              </div>
              <div className="hid-grid__column hid-grid__column--12-xs">
                <span className="hid-origo__fs-32 hid-origo__bold-text">
                  {t("READERS.RESET_SMALL")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hid-grid__column hid-grid__column--4-xs hid-origo__mt-10">
          <div className="hid-origo__grey-card hid-layout__mt-04">
            <div className="hid-grid">
              <div className="hid-grid__column hid-grid__column--10-xs ">
                <div className="hid-grid">
                  <div className="hid-grid__column hid-grid__column--9-xs hid-origo__mt-10 hid-origo__flex-center">
                    <div className="hid-grid hid-origo__flex-center">
                      <div className="hid-grid__column hid-grid__column--12-xs">
                        <img src={ReaderGreenIcon} alt="reader-icon-green" />
                      </div>
                      <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10 ">
                        {t("READERS.READERS_READY_FOR_RESET")}
                      </div>
                      <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                        <span className="hid-origo__bold-text">
                          {`1 Reader`}
                        </span>
                      </div>
                      <div className="hid-spacing__m-03"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hid-grid hid-grid--center-xs">
        <div className="hid-grid__column hid-grid__column--2-xs hid-origo__mt-35">
          <Button
            variant="secondary"
            onClick={onClose}
            label={t("READERS.BACK")}
          />
        </div>
        <div className="hid-grid__column hid-grid__column--3-xs hid-origo__mt-35 hid-origo__flex-right">
          <Button
            variant="primary"
            onClick={onReset}
            label={t("READERS.START_APPLYING_CONFIGURATION")}
          />
        </div>
      </div>
    </Modal>
  );
}
