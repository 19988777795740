import {
  Iconography,
  Button,
  Typography,
  Link,
  Card,
  IconographySizes,
  Notification,
} from "@hid-galaxy-ui/galaxy-react";
import DCIDIcon from "../../../../assets/svgs/dcid_icon.svg";
import ReaderWithCircleIcon from "../../../../assets/svgs/reader_with_circle_icon.svg";
import ReaderGreenIcon from "../../../../assets/svgs/reader_green_icon.svg";
import Device from "../../../../assets/images/device.png";
import ReaderOrangeIcon from "../../../../assets/svgs/reader_orange_icon.svg";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useEffect, useState } from "react";
import { validateFirmwareService } from "../../../../services/readerAPIService";
import { setGlobalToasts } from "../../../../reducers/userInfoReducer";
import { buildNotification } from "../../../../utils/notification";
import { useDispatch } from "react-redux";
import { IReadersData } from "../types";
interface IReviewApplyDCID {
  back: () => void;
  dcid: {
    name: string;
    deviceType: string;
    identifier: string;
    status: string;
    description: string;
    lastUpdated: string;
    isDisabled: boolean;
  };
  readers: IReadersData[];
  onApplyConfiguration: (
    data: {
      deviceId: string;
      version: string;
    }[]
  ) => void;
}
export default function ReviewApplyDCID(props: IReviewApplyDCID) {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const [toast, setToasts] = useState([]) as any;
  const { dcid, readers, back, onApplyConfiguration } = props;
  const [invalidRedaers, setInvalidRedaers] = useState<
    {
      deviceId: string;
      version: string;
    }[]
  >([]);

  useEffect(() => {
    try {
      (async () => {
        await validateFirmware();
      })();
    } catch (error: any) {
      dispatch(setGlobalToasts([buildNotification("Error", error.message)]));
    }
  }, []);

  const validateFirmware = async () => {
    try {
      const payload = {
        deviceConfigurationIdentifier: dcid.identifier,
        deviceConfigurationIdentifierStatus: dcid.status,
        connectedDevice: readers.map((r: any) => ({
          deviceId: r.id,
          version: r.fwDisplayName,
        })),
      };
      const validatedReaders = await validateFirmwareService(payload);
      if (validatedReaders.data?.status?.code === 200) {
        setInvalidRedaers(validatedReaders.data.data.invalidDevice);
        validatedReaders.data.data.invalidDevice.length > 0 &&
          setToasts([
            buildNotification("Error", t("READERS.FIRMWARE_NOT_COMPATIBLE")),
          ]);
      }
    } catch (err) {}
  };

  return (
    <>
      <div className="hid-flex hid-flex-jc-center hid-spacing__mb-6">
        <Typography
          variant={TypographyVariantEnum.H1}
          className={"hid-spacing__mr-2"}
        >
          {t("READERS.REVIEW_AND_APPLY_CONFIGURATION")}
        </Typography>
      </div>
      <div className="hid-grid hid-grid--center-xs">
        <div className="hid-grid__column hid-grid__column--4-xs hid-grid__column--2-lg hid-hid-grid--center-xs hid-origo__mt-10">
          <div className="hid-origo__grey-card hid-layout__mt-04">
            <div className="hid-grid">
              <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                <img src={DCIDIcon} alt="reader-icon" />
              </div>
              <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                {t("READERS.SELECTED_CONFIGURATIONID")}
              </div>
              <div className="hid-grid__column hid-grid__column--12-xs">
                <span className="hid-origo__fs-32  hid-linq__white-space__nowrap">
                  {dcid.identifier}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hid-grid__column hid-grid__column--3-xs hid-origo__mt-10">
          <div className="hid-origo__grey-card hid-layout__mt-04">
            <div className="hid-grid">
              <div className="hid-grid__column hid-grid__column--10-xs ">
                <div className="hid-grid">
                  <div className="hid-grid__column hid-grid__column--9-xs hid-origo__mt-10 hid-origo__flex-center">
                    <div className="hid-grid hid-origo__flex-center">
                      <div className="hid-grid__column hid-grid__column--12-xs">
                        <img src={ReaderGreenIcon} alt="reader-icon-green" />
                      </div>
                      <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10 ">
                        {t("READERS.READERS_READY_FOR_CONFIGURATION")}
                      </div>
                      <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                        <span className="hid-origo__bold-text">
                          {`${readers.length - invalidRedaers.length} ${t(
                            "READERS.READERS"
                          )}`}
                        </span>
                      </div>
                      <div className="hid-spacing__m-03"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hid-grid hid-grid--center-xs">
        <div className="hid-grid__column hid-grid__column--2-xs hid-origo__mt-35">
          <Button
            variant="secondary"
            onClick={back}
            label={t("READERS.BACK")}
          />
        </div>
        <div className="hid-grid__column hid-grid__column--3-xs hid-origo__mt-35 hid-origo__flex-right">
          <Button
            variant="primary"
            onClick={() => onApplyConfiguration(invalidRedaers)}
            disabled={readers.length === invalidRedaers.length}
            label={t("READERS.START_APPLYING_CONFIGURATION")}
          />
        </div>
      </div>

      {invalidRedaers.length > 0 ? (
        <>
          <div className="hid-grid hid-grid--center-xs">
            <div
              className="hid-grid__column hid-grid__column--5-xs hid-origo__mt-35"
              style={{ border: "1px solid #DDDDDD" }}
            ></div>
          </div>
          <div className="hid-grid hid-grid--center-xs hid-spacing__mt-05">
            <div className="hid-grid__column hid-grid__column--5-xs hid-origo__mt-10">
              <div className="hid-flex hid-flex-jc-between">
                <Typography variant={TypographyVariantEnum.BodyShortMarketing}>
                  {t("READERS.READERS_FOUND_NOT_READY")}
                </Typography>
                <Typography variant={TypographyVariantEnum.BodyShortMarketing}>
                  <Link onClick={validateFirmware}>
                    {t("READERS.CHECK_AGAIN")}
                  </Link>
                </Typography>
              </div>
            </div>
          </div>

          <div className="hid-grid hid-grid--center-xs hid-spacing__mt-04">
            <div className="hid-grid__column hid-grid__column--5-xs">
              {invalidRedaers.map((reader: any) => (
                <Card>
                  <div className="hid-grid">
                    <div className="hid-grid__column hid-grid__column--1-xs">
                      <img
                        src={Device}
                        className="hid-origo__linq-reader-image"
                        alt=""
                        data-testid="reader-img"
                        width="40"
                        height="40"
                      />
                    </div>
                    <div className="hid-grid__column hid-grid__column--4-xs hid-flex hid-flex-ai-center">
                      {reader.deviceId}
                    </div>
                    <div className="hid-grid__column hid-grid__column--1-xs hid-flex hid-flex-ai-center">
                      <img src={ReaderOrangeIcon} alt="reader-icon" />
                    </div>
                    <div className="hid-grid__column hid-grid__column--6-xs hid-flex hid-flex-ai-center">
                      <Iconography
                        icon="circleExclamation"
                        className="hid-spacing__mr-02"
                        size={IconographySizes.Medium}
                        style={{ color: "red" }}
                      />
                      {t("READERS.FIRMWARE_NOT_COMPATIBLE")}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </>
      ) : null}
      <Notification
        toasts={toast}
        setToasts={setToasts}
        isSticky={false}
        isAutoClose={true}
      />
    </>
  );
}
