import SidenavLayout from "../widgets/Layout/SidenavLayout";
import Layout from "../widgets/Layout";

import Login from "../pages/login";

import ReadersPage from "../pages/Linq/readers";

import UpgradeFirmware from "../pages/Linq/upgradeFirmware";
import { USER_ROLES } from "./commonInterfaces/IFormField";
import ReadersConfigTemplate from "../pages/Linq/readersConfigTemplates";
import AppplyTemplate from "../pages/Linq/readersApplyTemplate";
import ResetReader from "../pages/Linq/readers/resetReader";
import ChangeOrganization from "../pages/Linq/changeOrganization";
import CreateDcid from "../pages/Linq/createDcid";
import TemplateEditor from "../pages/Linq/createDcid/components/TemplateEditor";
import ConfigurationSummary from "../pages/Linq/createDcid/components/ConfigurationSummary";
import DcidCopyTemplate from "../pages/Linq/createDcid/components/DcidCopyTemplate";

export const homePath = "/";
export const loginPath = "/login";
export const readersPath = "/readers";
export const readersApplyTemplatePath = "/readers/apply-template";
export const settingsPath = "/settings";
export const editTemplatePath = "/settings/edit-template/:id";
export const updateFirmwarePath = "/readers/updateFirmware";
export const readersConfigTemplate = "/readers-config-template";
export const resetReaderPath = "/readers/reset-reader";
export const createDcidPath = "/create-dcid";
export const changeOrganizationPath = "/changeOrganization";
export const templateEditorPath = "/templateEditor";
export const ConfigurationSummaryPath = "/configurationSummay";
export const DcidCopyTemplatePath = "/copy-dcid";
export interface IRouteConfig {
  path: string;
  component: React.ElementType;
  layout?: React.ElementType;
  isProtected?: boolean;
  allowedRoles?: USER_ROLES;
}

export const routesConfig: IRouteConfig[] = [
  {
    path: homePath,
    component: ReadersPage,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: loginPath,
    component: Login,
  },
  {
    path: changeOrganizationPath,
    component: ChangeOrganization,
    isProtected: true,
  },

  {
    path: readersPath,
    component: ReadersPage,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: updateFirmwarePath,
    component: UpgradeFirmware,
    layout: SidenavLayout,
    isProtected: true,
  },

  {
    path: readersConfigTemplate,
    component: ReadersConfigTemplate,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: readersApplyTemplatePath,
    component: AppplyTemplate,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: resetReaderPath,
    component: ResetReader,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: createDcidPath,
    component: CreateDcid,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: templateEditorPath,
    component: TemplateEditor,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: ConfigurationSummaryPath,
    component: ConfigurationSummary,
    layout: SidenavLayout,
    isProtected: true,
  },
  {
    path: DcidCopyTemplatePath,
    component: DcidCopyTemplate,
    layout: SidenavLayout,
    isProtected: true,
  },
];
