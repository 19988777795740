import {
  Divider,
  Iconography,
  Modal,
  ModalVariants,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import Device from "../../../../assets/images/device.png";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { DividerVariants } from "@hid-galaxy-ui/galaxy-react/components/Divider/dividerEnums";
import { useState, useEffect } from "react";

interface IReaderSpecsProps {
  setModalOpen: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function ReaderSpecs(props: IReaderSpecsProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const initialData = {
    readerSpecs: [
      { title: t("READERS.HID_PART_NMBER"), value: "MID-SUB-CRD" },
      { title: t("READERS.MOB_KEYSET"), value: "MOB0970" },
      { title: t("READERS.FORMAT"), value: "TRK-8329" },
      { title: t("READERS.FACILITY_CODE"), value: "11223" },
    ],
    currentInventory: [
      { title: t("READERS.QUANTITY"), value: "810 available" },
      { title: t("READERS.NEXT_CRED_VALUE"), value: "31 Mar, 16:32" },
      { title: t("READERS.HIGH_CRED_VALUE"), value: "A1235566" },
      {
        title: t("READERS.LAST_SERIES_ADDED"),
        value: "2235-234 on May 3 2022",
      },
      { title: t("READERS.REPLENISH_STATUS"), value: "Not Needed" },
    ],
  };
  const [readerSpecsData, setReaderSpecsData] = useState(initialData);

  useEffect(() => {
    // setReaderSpecsData(readerSpecsData);
  }, [readerSpecsData]);

  return (
    <Modal
      size={ModalVariants.Large}
      onClose={props.setModalOpen}
      footerConfig={{
        secondaryBtnLabel: "Done",
        onClickPrimaryBtn: () => {},
      }}
    >
      <>
        <div className="hid-grid">
          <img src={Device} alt="" height={101} data-testid="reader-img" />
          <div className="hid-grid__column hid-grid__column--3-xs hid-flex hid-flex-column hid-flex-jc-center">
            <Typography variant={TypographyVariantEnum.H2}>
              Frankfurt Garage
            </Typography>
            <Typography variant={TypographyVariantEnum.TextSmall}>
              This is your Pass description
            </Typography>
          </div>
          <div className="hid-grid__column hid-grid__column--3-xs hid-flex hid-flex-row hid-layout__mt-05">
            <Iconography icon="circleCheck" theme="Success" size="Medium" />
            <Typography
              variant={TypographyVariantEnum.H3}
              className="hid-layout__ml-03"
            >
              Connected
            </Typography>
          </div>
        </div>
        <div className="hid-layout__mt-03">
          <Divider mode={DividerVariants.Canvas} />
        </div>
        <div className="hid-grid hid-layout__mt-08">
          <div className="hid-grid__column hid-grid__column--5-xs">
            <Typography
              variant={TypographyVariantEnum.H2}
              className="hid-layout__ml-03 hid-layout__mb-02"
            >
              {t("READERS.READER_SPEC_TITLE")}
            </Typography>
            {readerSpecsData.readerSpecs.map((spec) => {
              return (
                <>
                  <div className="hid-grid hid-layout__mt-01">
                    <div className="hid-grid__column hid-grid__column--6-xs">
                      <Typography
                        variant={TypographyVariantEnum.BodyShortMarketing}
                        className="hid-layout__ml-03"
                      >
                        {spec.title}
                      </Typography>
                    </div>
                    <div className="hid-grid__column hid-grid__column--6-xs">
                      <Typography
                        variant={TypographyVariantEnum.H3}
                        className="hid-origo__linq-reader-specs-top-margin"
                      >
                        {spec.value}
                      </Typography>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="hid-grid__column hid-grid__column--7-xs">
            <Typography
              variant={TypographyVariantEnum.H2}
              className="hid-layout__ml-03 hid-layout__mb-02"
            >
              {t("READERS.CURRENT_INVENTORY")}
            </Typography>
            {readerSpecsData.currentInventory.map((inventory) => {
              return (
                <>
                  <div className="hid-grid hid-layout__mt-01">
                    <div className="hid-grid__column hid-grid__column--6-xs">
                      <Typography
                        variant={TypographyVariantEnum.BodyShortMarketing}
                        className="hid-layout__ml-03"
                      >
                        {inventory.title}
                      </Typography>
                    </div>
                    <div className="hid-grid__column hid-grid__column--6-xs">
                      <Typography
                        variant={TypographyVariantEnum.H3}
                        className="hid-origo__linq-reader-specs-top-margin"
                      >
                        {inventory.value}
                      </Typography>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    </Modal>
  );
}
