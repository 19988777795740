import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Iconography,
  IconographySizes,
  Modal,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import Template from "../../../assets/images/readersConfig.png";
import { ISubMenu } from "@hid-galaxy-ui/galaxy-react/components/DropDown/IDropdownProps";
import cloudUpload from "../../../assets/images/cloudupload.png";
import filePlus from "../../../assets/images/filePlus.png";
import { useNavigate } from "react-router-dom";
import { DcidCopyTemplatePath, templateEditorPath } from "../../../utils/routes";
export enum TemplateType {
  NEW = "NEW",
  REFERENCE = "REFERENCE",
  COPY = "COPY",
}
const CreateDcid = () => {
  const [selectedDeviceConfigure, setSelectedDeviceConfigure] =
    useState<ISubMenu | null>();
  const navigate = useNavigate();
  return (
    <main className="hid-fixed-container__main">
      <Typography variant={TypographyVariantEnum.H1}>
        Device Configuration Templates
      </Typography>
      <div className="hid-linq__white-card hid-layout__mt-04">
        <div className="hid-linq__device-config-empty-page">
          <img src={Template} alt="" data-testid="template-img" />
          <Typography
            className="hid-layout__mt-03"
            variant={TypographyVariantEnum.BodyShortProduct}
          >
            This is where your Device Configuration
          </Typography>
          <Typography variant={TypographyVariantEnum.BodyShortProduct}>
            Template(s) will appear
          </Typography>
          <div className="hid-origo__dropdown hid-layout__mt-03">
            <Dropdown
              id="dropdown"
              selected={selectedDeviceConfigure}
              placeholder={"CREATE DEVICE CONFIGURATION TEMPLATE"}
              options={[
                {
                  label: "SIGNO",
                  value: "SIGNO",
                },
                {
                  label: "SIGNO 25B",
                  value: "SIGNO 25B",
                },
              ]}
              onSelect={setSelectedDeviceConfigure}
            />
          </div>
        </div>
      </div>
      {selectedDeviceConfigure && (
        <Modal
          width={1127}
          isDisplayCloseIcon={false}
          isFocusTrapActive={false}
        >
          <>
            <div className="hid-flex hid-flex-jc-end">
              <button
                className="hid-modal__close"
                onClick={() => setSelectedDeviceConfigure(null)}
              >
                <Iconography icon="xmark" />
              </button>
            </div>
            <Typography
              className="hid-text-center"
              variant={TypographyVariantEnum.H1}
            >
              Select in order to Continue
            </Typography>
            <div className="hid-linq__card-wrapper">
              <div className="hid-linq__card-container">
                <div className="hid-linq__top-media">
                  <img src={cloudUpload} alt=""></img>
                  <Iconography
                    className="hid-linq__card-icon"
                    icon="magnifyingGlass"
                    size={IconographySizes.Medium}
                  />
                  {/* <Iconography icon="xmark"  /> */}
                </div>
                <div className="hid-linq__bottom-body">
                  <Typography
                    className="hid-layout__mt-04"
                    variant={TypographyVariantEnum.H2}
                  >
                    Start from Existing Template
                  </Typography>
                  <Typography
                    className="hid-layout__mt-04"
                    variant={TypographyVariantEnum.BodyShortProduct}
                  >
                    Enter the DCID you wish to copy
                  </Typography>
                  <Typography
                    className="hid-layout__mt-05"
                    variant={TypographyVariantEnum.TextSmall}
                  >
                    *If the DCID you choose has a parent you will be using it
                  </Typography>
                  <Button
                    className="hid-layout__mt-03"
                    label="copy existing"
                    icon={<Iconography icon="magnifyingGlass" />}
                    onClick={()=> navigate(DcidCopyTemplatePath)}
                  />
                </div>
              </div>
              <div className="hid-linq__card-container">
                <div className="hid-linq__top-media">
                  <img src={filePlus} alt=""></img>
                  <Iconography
                    className="hid-linq__card-icon"
                    icon="plus"
                    size={IconographySizes.Medium}
                  />
                </div>
                <div className="hid-linq__bottom-body">
                  <Typography
                    className="hid-layout__mt-04"
                    variant={TypographyVariantEnum.H2}
                  >
                    Start New Template
                  </Typography>
                  <Typography
                    className="hid-layout__mt-04"
                    variant={TypographyVariantEnum.BodyShortProduct}
                  >
                    Create a new Signo
                  </Typography>
                  <Typography variant={TypographyVariantEnum.BodyShortProduct}>
                    Configuration from Default
                  </Typography>
                  <Button
                    className="hid-layout__mt-07"
                    label="Create"
                    icon={<Iconography icon="fileCheck" />}
                    onClick={() =>
                      navigate(templateEditorPath, {
                        state: { type: TemplateType.NEW },
                      })
                    }
                  />
                </div>
              </div>
              <div className="hid-linq__card-container">
                <div className="hid-linq__top-media">
                  <div className="hid-linq__card-icon-file">
                    <Iconography icon="file" size={IconographySizes.Medium} />
                  </div>
                  <img src={filePlus} alt=""></img>
                  <div className="hid-linq__card-icon">
                    <Iconography icon="plus" size={IconographySizes.Medium} />
                  </div>
                </div>
                <div className="hid-linq__bottom-body">
                  <Typography
                    className="hid-layout__mt-04"
                    variant={TypographyVariantEnum.H2}
                  >
                    Start New Template that
                  </Typography>
                  <Typography variant={TypographyVariantEnum.H2}>
                    references a parent DCID
                  </Typography>
                  <Typography
                    className="hid-layout__mt-03"
                    variant={TypographyVariantEnum.BodyShortProduct}
                  >
                    Create a new Signo Configuration
                  </Typography>
                  <Typography variant={TypographyVariantEnum.BodyShortProduct}>
                    Template by reference ID
                  </Typography>
                  <Button
                    className="hid-layout__mt-05"
                    label="reference parent"
                    icon={<Iconography icon="magnifyingGlass" />}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
    </main>
  );
};
export default CreateDcid;
