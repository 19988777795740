export const codeLength = 8;

export const truncatedText = (text: String, truncateCharLimit: number) => {
  if (text == null || text.length <= truncateCharLimit) {
    return text;
  }
  return `${text.slice(0, truncateCharLimit)}...`;
};
export function getNextGroupName(processes:Object) {
  // Initialize the highest group number
  let highestGroupNumber = 0;

  // Iterate through each process to find the highest group number
  Object.values(processes).forEach(process => {
    if (process.type === 'upgrade' && process.name) {
      // Extract the number from the group name
      const groupNumber = parseInt(process.name.replace('Group', ''), 10);
      if (groupNumber > highestGroupNumber) {
        highestGroupNumber = groupNumber;
      }
    }
  });
  // Increment the highest group number to get the next group name
  const nextGroupName = `Group ${highestGroupNumber + 1}`;
  return nextGroupName;
}
export function countRunningProcesses(processes: any) {
  const processTypes = new Set();
  let upgradeCounted = false;

  for (const key in processes) {
    const process = processes[key];
    // if (process.isCompleted) {
      if (process.type === "upgrade" && !upgradeCounted) {
        processTypes.add("upgrade");
        upgradeCounted = true;
      } else if (process.type !== "upgrade") {
        processTypes.add(process.type);
      }
    // }
  }

  return processTypes.size;
}

export const isUSBReader = (port: string) => {
  if (port?.startsWith("COM")) {
    return true;
  }
  return false;
};

export const colors = [
  { name: "White", color: "white" },
  { name: "Black", color: "black" },
  { name: "Blue", color: "blue" },
  { name: "Red", color: "red" },
];

export const GATEWAY_DEFAULT_LISTING_COUNT = 5;
export const READER_MANAGER_ADMIN_ROLE = "ReaderManagerAdmin";
//export const READER_MANAGER_ADMIN_ROLE = "RL_ORGADMIN";
export const applyTemplateResultsPageSize = 4;
export const readerNotificationCloseDelay = 15000;
export const readerFriendlyNameLimit = 50;