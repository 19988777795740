import {
  Button,
  Iconography,
  IconographySizes,
  Modal,
  ModalVariants,
  Notification,
  Typography,
  TypographyVariant,
} from "@hid-galaxy-ui/galaxy-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NAMESPACE, getCommonNS } from "../../../../utils/i18nUtils";
import ReaderIcon from "../../assets/icons/reader.svg";
import { useSelector } from "react-redux";
import { upgradeFirmwareData } from "../../../../reducers/upgradeFirmwareReducer";
import { buildNotification } from "../../../../utils/notification";
interface IUpdateFirmwareModal {
  setModal: (data: boolean) => void;
  updateFirmware: () => void;
  selectedFirmware: string;
  selectedReaders: any[];
}
export default function UpdateFirmwareModal(props: IUpdateFirmwareModal) {
  const { t } = useTranslation(NAMESPACE.READER);
  const { setModal, selectedFirmware, updateFirmware, selectedReaders } = props;
  const {latestFWVersion} = useSelector(upgradeFirmwareData);
  const [toasts, setToasts] = React.useState([]) as any;


  return (
    <>
    <Modal
      size={ModalVariants.Medium}
      isFocusTrapActive={false}
      isDisplayCloseIcon={true}
      onClose={() => setModal(false)}
    >
      <header className="hid-flex hid-spacing__pt-03 hid-spacing__pl-08">
        <div className="hid-modal__header-headline hid-text-center">
          <Typography
            className="hid-text-center"
            variant={TypographyVariant.ProfileHeader}
          >
            {t("READERS.UPDATE_FIRMWARE_SMALL")}
          </Typography>
        </div>
        {/* <Button
          className="hid-origo__icon-negative-mt "
          variant="flat"
          id="closeIcon"
          data-testid="closeIcon"
          icon={<Iconography icon="xmark" size={IconographySizes.Small} />}
          onClick={() => setModal(false)}
        /> */}
      </header>
      <div className="hid-modal__header-headline hid-text-center">
        <Typography
          className="hid-spacing__mt-04"
          variant={TypographyVariant.BodyLongMarketing}
        >
          {t("READERS.UPDATED_SELECTED_TEXT")}
        </Typography>
        <Typography variant={TypographyVariant.BodyLongMarketing}>
          {t("READERS.FIRMWARE_VERSION_TEXT")}
        </Typography>
        <Typography variant={TypographyVariant.BodyLongMarketing}>
          {t("READERS.STARTED_UPDATED_TEXT")}
        </Typography>
      </div>
      <div className="hid-spacing__mt-06 hid-flex  hid-flex-jc-center hid-flex-ai-center">
        {<img src={ReaderIcon} alt="" />}
        <Typography
          className="hid-spacing__ml-04 hid-linq__reader-name"
          variant={TypographyVariant.BodyShortMarketing}
        >
          {selectedReaders?.length > 1
            ? `${selectedReaders.length} Readers`
            : selectedReaders[0]?.id}
        </Typography>
      </div>
      <div className="hid-spacing__mt-05 hid-flex hid-flex-jc-center">
        <Iconography icon="arrowsRepeat" size={IconographySizes.Medium} />
        <Typography
          className="hid-spacing__ml-04 hid-spacing__mr-04"
          variant={TypographyVariant.BodyShortMarketing}
        >
          {selectedFirmware}
        </Typography>
      </div>
      <div className="hid-text-center hid-spacing__mt-06">
        <Button
          label={t("READERS.YES_UPDATED_FIRAMRE_VERSION")}
          onClick={updateFirmware}
        />
      </div>
      <div className="hid-text-center hid-spacing__mt-05">
        <Button
          variant="secondary"
          label={t("COMMON.CANCEL", getCommonNS())}
          onClick={() => setModal(false)}
        />
      </div>
    </Modal>
    </>
  );
}
