import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

export interface InitialStateDataProps {
  templateData: any;
}



const initialState: InitialStateDataProps = {
  templateData:{}
};

const templateCreateInfoSlice = createSlice({
  name: "templateCreateInfo",
  initialState,
  reducers: {
    setTemplateData: (state, action: PayloadAction<InitialStateDataProps>) => {
      const { payload } = action;
      state.templateData = payload;
    },
  },
});

export default templateCreateInfoSlice.reducer;
export const { setTemplateData } =
templateCreateInfoSlice.actions;
export const temlateCreateInfoData = (state: RootState) => state.templateCreate;