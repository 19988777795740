import {useState} from "react";
import { Button, Iconography, SearchInput } from "@hid-galaxy-ui/galaxy-react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../../utils/i18nUtils";
import { fetchDCIDService } from "../../../../../services/readerAPIService";

interface ISearchBar {
    setSearchedDCIDConfig: (data: any) => void,
}

const DcidSearchBar = (props: ISearchBar) => {
    const {t} = useTranslation(NAMESPACE.READER);
    const [searchString, setSearchString] = useState("");

const handleOnSearch = async () => {
    try {
        const res = await fetchDCIDService(searchString);
        props.setSearchedDCIDConfig(res.data)
    }
    catch(error) {
        console.error(error);
    }
}

    return (
      <div className="hid-origo__filter-container hid-linq__border-radius-04">
        <div className="hid-grid hid-spacing__p-05">
          <div className="hid-grid hid-grid__column--4-xs hid-spacing__pl-06 hid-linq__search-input hid-linq__border-radius-04">
            <SearchInput
              id="search"
              name="search"
              onSearch={(_, data: any) => setSearchString(data)}
              onChange={(e: any) => setSearchString(e.target.value)}
              placeholder={t("DCID_COPY.SEARCH_CONFIGURATION")}
            />
          </div>
          <div className="hid-grid hid-grid__column--4-xs hid-layout__ml-03 hid-linq__btn-bg">
            <Button
              variant="flat"
              label="search"
              onClick={handleOnSearch}
              icon={<Iconography icon="magnifyingGlass" />}
            />
          </div>
        </div>
      </div>
    );
  };

  export default DcidSearchBar;