import { ReactElement } from "react";
import "./StatusBox.scss";

interface IStatusBoxProps {
  message: string|ReactElement ;
  icon: ReactElement;
  type: StatusTypes;
}
export enum StatusTypes {
  success = "success",
  warning = "warning",
  error = "error",
  info = "info",
  blank="blank"
}
export default function StatusBox(props: IStatusBoxProps) {
  const { message, icon, type } = props;
  const statusToClassMapper = {
    success: "status-success",
    warning: "status-warning",
    error: "status-error",
    info: "status-info",
    blank: "status-blank",
  };
  return (
    <div className={`status-box ${statusToClassMapper[type]}`}>
      {icon}
      <span className="hid-spacing__ml-04">{message}</span>
    </div>
  );
}
