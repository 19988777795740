// @ts-ignore
// import { TemplateForm } from "@hidglobal/jfrc-template-components";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../../utils/i18nUtils";
import BreadCrumbs from "../../../../../widgets/BreadCrumbs";
import {
  ConfigurationSummaryPath,
  createDcidPath,
} from "../../../../../utils/routes";
import { setTemplateData } from "../../../../../reducers/templateCreationReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import CancelModal from "./CancelModal";
import { useDispatch } from "react-redux";
export default function TemplateEditor() {
  const { t } = useTranslation(NAMESPACE.READER);
  const location = useLocation();
  const state = location.state;
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const navigateToCreateDCID = () => {
    navigate(createDcidPath);
  };
  const getBreadCrumbsData = () => {
    return [
      {
        name: t("READERS.DEVICE_CONFIGURATIONS"),
        href: createDcidPath,
        isCurrentPage: false,
      },
      {
        name: t("READERS.START_NEW_CONFIGURATION"),
        href: createDcidPath,
        isCurrentPage: false,
      },
      {
        name: t("READERS.CONFIGURATION_EDITOR"),
        href: "#",
        isCurrentPage: true,
      },
    ];
  };
  const onSaveTemplate = (data: any) => {
    dispatch(setTemplateData(data));
    setTemplateData(data);
    navigate(ConfigurationSummaryPath);
  };
  return (
    <>
      <div className="hid-template-editor">
        <div className="hid-layout__px-07 hid-layout__py-03">
          <BreadCrumbs data={getBreadCrumbsData()} />
        </div>
        {/* <TemplateForm
          name={t("READERS.CONFIGURATION_EDITOR")}
          template={null}
          schema={null}
          onSaveDcid={onSaveTemplate}
          onBack={() => setIsCancelModalOpen(true)}
        /> */}
      </div>
      {isCancelModalOpen && (
        <CancelModal
          onClose={() => setIsCancelModalOpen(false)}
          onCancel={navigateToCreateDCID}
        />
      )}
    </>
  );
}
