import {
  ContextualMenu,
  Iconography,
  IconographySizes,
  Pagination,
  SearchInput,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import "./header.scss";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { NAMESPACE } from "../../../utils/i18nUtils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  loginInfoData,
  resetLoginInfo,
  setSelectedCustomerInfo,
} from "../../../reducers/userInfoReducer";
import OrganizationCard from "./organizationCard";
import { homePath, loginPath } from "../../../utils/routes";
import { useNavigate, useLocation } from "react-router";
import { useEffect, useState, useCallback } from "react";
import { resetStateToDefault } from "../../../reducers/applyTemplateReducer";
import { resetFWStateToDefault } from "../../../reducers/upgradeFirmwareReducer";
import { clearReaders } from "../../../reducers/discoverReadersReducer";
import { callLogoutService } from "../../../services/loginAPIService";
import MultipleSessionModal from "../../../widgets/MultiplSessionModal";

interface IPagination {
  currentPageNumber: number;
  totalRecords: number;
  pageSize: number;
}
export default function ChangeOrganization() {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [existingSession, setExistingSession] = useState(
    (state && state.existingSession) || 0
  );
  useEffect(() => {
    if (state && state.existingSession) {
      window.history.replaceState({}, document.title);
    }
  }, [state && state.existingSession]);
  const [searchText, setSearchText] = useState<string>();
  const { linq_enabled_orgs, userInfo, selectedCustomerInfo } =
    useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  const [tableData, setTableData] = useState<any[]>([]);
  const [paginationConfig, setPaginationConfig] = useState<IPagination>({
    currentPageNumber: 1,
    totalRecords: linq_enabled_orgs?.length || 0,
    pageSize: 5,
  });

  const onOrganizationSelect = (data: {
    customerId: number;
    customerName: string;
  }) => {
    dispatch(setSelectedCustomerInfo(data));
    navigate(homePath);
  };
  useEffect(() => {
    if (paginationConfig.pageSize) {
      const pageData = linq_enabled_orgs.slice(
        (paginationConfig.currentPageNumber - 1) * paginationConfig.pageSize,
        (paginationConfig.currentPageNumber - 1) * paginationConfig.pageSize +
          paginationConfig.pageSize
      );
      setTableData(pageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const onPageNumberClick = (pageNumber: any) => {
    setPaginationConfig({ ...paginationConfig, currentPageNumber: pageNumber });
  };

  const fiteredData = () => {
    if (searchText) {
      const lowercasePattern = searchText.toLowerCase();
      return tableData.filter((obj) =>
        obj.customerName.toLowerCase().includes(lowercasePattern)
      );
    } else {
      return tableData;
    }
  };
  const onLogout = async (key: string) => {
    await callLogoutService();
    localStorage.clear();
    dispatch(resetStateToDefault());
    dispatch(resetFWStateToDefault());
    dispatch(resetLoginInfo());
    dispatch(clearReaders());
    navigate(loginPath);
  };

  const filteredOrgs = fiteredData();
  return (
    <>
      <div className="hid-linq-changeOrg hid-grid">
        <div className=" hid-linq-changeOrg__header hid-grid__column hid-grid__column--5-xs hid-spacing__p-08">
          <div className="hid-flex hid-flex-jc-between">
            <div className="hid-linq__logo">HID Linq</div>
            <li className="hid-header__actions__list-item">
              <ContextualMenu
                contextualMenuData={[
                  {
                    data: [
                      {
                        key: "logout",
                        value: "Logout",
                      },
                    ],
                  },
                ]}
                onClick={(e, item) => onLogout(item.key)}
                placement={"bottom-end"}
                isToogle={true}
              >
                <button className="header-action">
                  <Iconography
                    icon={"circleUser"}
                    size={IconographySizes.Medium}
                  />

                  <>
                    <span className="hid-header__action__text">
                      {userInfo?.email}
                    </span>
                    <Iconography icon="angleDown" />
                  </>
                </button>
              </ContextualMenu>
            </li>
          </div>
          <div className="hid-layout__mt-05">
            <Typography variant={TypographyVariantEnum.H1}>
              {t("READERS.ORGANIZATIONS")}
            </Typography>
          </div>
          <div className="hid-layout__mt-05">
            <SearchInput
              id="organizationSearch"
              name="organizationSearch"
              placeholder={t("READERS.CHANGE_ORG_SEARCH_PLACEHOLDER")}
              onSearch={(_, searchText) => setSearchText(searchText)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText(event.target.value);
              }}
            />
          </div>
          <div className="hid-layout__mt-05">
            <Typography variant={TypographyVariantEnum.H4}>
              {t("READERS.GOTO_LINQ")}
            </Typography>
          </div>
          {filteredOrgs.map((customer: any) => {
            return (
              <OrganizationCard
                name={customer?.customerName}
                id={customer?.customerId}
                onClick={onOrganizationSelect}
                selected={customerId == customer?.customerId}
              />
            );
          })}
          {filteredOrgs.length > paginationConfig.pageSize ? (
            <Pagination
              currentPageNumber={paginationConfig.currentPageNumber || 0}
              totalRecords={paginationConfig.totalRecords}
              pageSize={paginationConfig.pageSize}
              onPageNumberClick={onPageNumberClick}
            />
          ) : null}
        </div>
      </div>
      {existingSession > 0 && (
        <MultipleSessionModal closeModal={() => setExistingSession(false)} />
      )}
    </>
  );
}
