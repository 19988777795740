import { Card, Iconography } from "@hid-galaxy-ui/galaxy-react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";

export default function FirmwareVersions(props: {
  version: string;
  versionDesc: string;
  isCurrentVersion: boolean;
  isSelected: boolean;
  onClick: (version: string) => void;
  disabled: boolean;
}) {
  const { t } = useTranslation(NAMESPACE.READER);
  const {
    version,
    versionDesc,
    isCurrentVersion,
    isSelected,
    onClick,
    disabled,
  } = props;

  return (
    <Card
      className="hid-layout__mt-04"
      variant="selectable"
      cardSelectedIcon={<Iconography icon=" " />}
      selected={isSelected}
      isDisabled={disabled}
      onClick={() => !disabled && onClick(version)}
    >
      <div className="hid-flex hid-layout__p-02">
        <h2 className="hid-typography__h2">{version}</h2>
        <p className="hid-typography__text--helper line-height-24 hid-spacing__ml-04">
        {versionDesc}
        </p>
        {isCurrentVersion && (
          <p className="hid-typography__text--helper line-height-24 hid-linq__default-color">
            <Iconography icon="circleStar" size="Medium" /> {t("READERS.CURRENT_FIRMWARE_VERSION")}
          </p>
        )}
      </div>
    </Card>
  );
}
