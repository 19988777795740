import {
  Button,
  Iconography,
  Modal,
  ModalVariants,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import React from "react";
import { useTranslation } from "react-i18next";
import { getCommonNS, NAMESPACE } from "../../../utils/i18nUtils";

export default function ProtocolModal({
  onClose,
  onContinue,
  content,
  title,
}: {
  onClose: () => void;
  onContinue: () => void;
  content: string;
  title: string;
}) {
  const { t } = useTranslation(NAMESPACE.READER);
  return (
    <Modal size={ModalVariants.Medium} onClose={onClose}>
      <div className="hid-grid hid-grid--center-xs hid-spacing__p-07">
        <div className="hid-grid__column--10-xs hid-text-center">
          <Typography variant={TypographyVariantEnum.H2}>
            {/* {t("READERS.APPLY_CONFIGURATION").toUpperCase()} */}
            {title}
          </Typography>
        </div>
        <Typography
          variant={TypographyVariantEnum.BodyShortProduct}
          className="hid-spacing__mt-05"
        >
          {content}
        </Typography>
      </div>

      <div className="hid-flex hid-flex-jc-between ">
        <Button
          variant="secondary"
          label={t("COMMON.CANCEL", getCommonNS())}
          onClick={onClose}
        />
        <Button
          variant="primary"
          onClick={onContinue}
          label={t("READERS.CONTINUE")}
        />
      </div>
    </Modal>
  );
}
