import { USER_ROLES } from "./commonInterfaces/IFormField";
import store from "../store/store";
import browserUpdate from "browser-update";
import { detect } from "detect-browser";
import chromeImage from "./../assets/images/chrome.png";
import firefoxImage from "./../assets/images/firefox.png";
import edgeImage from "./../assets/images/edge.png";
import safariImage from "./../assets/images/safari.png";
export interface IRoleAndPrivilege {
  permissions: any;
  roleId: number;
  roleName: string;
}
const { loginInfo } = store.getState();
export const isUserLoggedIn = (): boolean => {
  const acceptedTOSObj = localStorage.getItem("acceptedTOS");
  const { acceptedTos } = (acceptedTOSObj && JSON.parse(acceptedTOSObj)) || {};
  return acceptedTos || false;
};
export const getUserDetails = () => {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) return JSON.parse(userDetails);
};

export const hasHidAdminRole = (role: USER_ROLES) => {
  const loggedInUserDetails = getUserDetails();
  const rolesAndPrivileges = loggedInUserDetails?.userInfo?.roles;
  return rolesAndPrivileges?.some(
    (data: IRoleAndPrivilege) => data?.roleName === role
  );
};
export function showOutdatedBrowserNotification() {
  const browser = detect();
  let browserImage = "";
  let updateUrl = "";

  switch (browser && browser.name) {
    case "chrome":
      browserImage = chromeImage;
      updateUrl = "https://www.google.com/chrome/browser/desktop/";
      break;
    case "firefox":
      browserImage = firefoxImage;
      updateUrl = "https://www.mozilla.org/firefox/new";
      break;
    case "edge":
    case "edge-chromium":
    case "ie":
    case "edge-ios":
      browserImage = edgeImage;
      updateUrl = "https://www.microsoft.com/edge";
      break;
    case "safari":
      browserImage = safariImage;
      updateUrl = "https://support.apple.com/en_IN/downloads/safari";
      break;
    default:
      console.log("not supported");
  }
  browserUpdate({
    required: { e: -3, f: -3, s: -2, c: -3 },
    reminder: 24,
    insecure: true,
    api: 2024.03,
    url: updateUrl,
    text:
      "<div class='buorg-main-div'><div><img src=" +
      browserImage +
      " class='buorg-browser-img'/></div><div class='burog-main-text'><span class='buorg-bold-text'>Your web browser ({brow_name}) is out of date</span><br>Update your browser for more security, speed and the best experience on this site</div><div class='buorg-btn-div'><a{up_but}>UPDATE BROWSER</a>  <a{ignore_but}>IGNORE</a></div></div>",
    container: document.body,
  });
}
export const getUserInfo = () => {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    return JSON.parse(userDetails).userInfo;
  }
};
const options: any = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
  timeZone: "UTC",
  timeZoneName: "short",
};
export const getUTCTime = (date: string) => {
  return new Date(date).toLocaleString("en-US", options);
};
export const isVentoReader = (readerType: string) => {
  return readerType.toLowerCase().includes("vento");
};
