import OrigoFooter from "../OrigoFooter";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";

interface IChildProps {
  children: JSX.Element;
}
export default function CenteredLayout(props: IChildProps) {
  const { t } = useTranslation(NAMESPACE.READER);

  return (
    <>
      <main className="hid-fixed-container__main hid-origo__background">
        <div>
          <div className="hid-text-center hid-spacing__pt-06">
            <span className="logo-text">{t("READERS.TITLE_TEXT")}</span>
          </div>
          <div className="hid-origo__foreground">{props.children}</div>
        </div>
      </main>
      <OrigoFooter />
    </>
  );
}
