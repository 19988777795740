import {
  BreadcrumbItem,
  Breadcrumbs,
  Iconography,
} from "@hid-galaxy-ui/galaxy-react";

interface IBreadCrumbsProps {
  data?: IBreadCrumbs[];
}
interface IBreadCrumbs {
  name: string;
  href: string;
  isCurrentPage: boolean;
}

export default function BreadCrumbs(props: IBreadCrumbsProps) {
  const { data } = props;
  return (
    <Breadcrumbs noTrailingSlash={true}>
      {data?.map((breadcrumb: IBreadCrumbs) => (
        <BreadcrumbItem
          name={breadcrumb.name}
          href={breadcrumb.href}
          isCurrentPage={breadcrumb.isCurrentPage}
          breadcrumbSeparator={<Iconography icon="chevronRight" />}
        ></BreadcrumbItem>
      ))}
    </Breadcrumbs>
  );
}
