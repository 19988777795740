import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Divider,
  Iconography,
  Notification,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { getCommonNS, NAMESPACE } from "../../../utils/i18nUtils";
import ChosenConfiguration from "./chosenConfiguration";
import FirmwareVersions from "./firmwareVersions";
import ReaderInfo from "./readerInfo";
import { readersPath } from "../../../utils/routes";
import UpdateFirmwareModal from "./UpdateFirmwareModal";
import { useDispatch, useSelector } from "react-redux";
import {
  upgradeFirmwareData,
  setSelectedFirmware,
  setUpgradeFirmwareProgressModalOpen,
  setUpdateFirmwareResponse,
  setChoosenReaders,
} from "../../../reducers/upgradeFirmwareReducer";
import {
  IAvailableFirmwareResponse,
  getAvailableFirmwareService,
  updateFirmwareService,
} from "../../../services/readerAPIService";
import {
  loginInfoData,
  setGlobalToasts,
} from "../../../reducers/userInfoReducer";
import {
  buildNotification,
  showDeviceBusyNotification,
} from "../../../utils/notification";
import {
  applyTemplateData,
  setRunningProcesses,
  setSelectedCorrelationId,
} from "../../../reducers/applyTemplateReducer";
import { connectToUpgradeFirmware } from "../../../App";
import { getNextGroupName } from "../../../utils/commonConst";
export interface IFirmare {
  deviceId: string;
  deviceTyp: string;
  currentSoftwareVersion: string;
  applicableSoftwareVersions:
    | {
        softwareVersion: string;
        preConditions?: { gatewayVersions: string[] };
      }[]
    | [];
}

export const readerFirmwareErrors = ["FW version Not Found"];
const softwareVersion = "R10.0.2.4";

export default function UpgradeFirmware() {
  const { t } = useTranslation(NAMESPACE.READER);
  const { selectedreaders, selectedFirmware, availableFirmwareVersions } =
    useSelector(upgradeFirmwareData);
    const {runningProcesses} = useSelector(applyTemplateData);
  const { selectedGateway, selectedCustomerInfo } = useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModal, setModal] = useState<boolean>(false);
  const [clickedUpgradeVersion, setClickedUpgradeVersion] = useState("");
  const [availableFirmware, setAvailableFirmware] =
    useState<IAvailableFirmwareResponse>(
      availableFirmwareVersions as IAvailableFirmwareResponse
    );
  const [showError, setShowError] = useState<String>("");
  const [toasts, setToasts] = useState([]) as any;

  const updateFirmware = async () => {
    try {
      const readersForUpgrade = selectedreaders.map((reader) => {
        return reader.id;
      });
      const payload = {
        data: [
          {
            firmwareVersion: selectedFirmware,
            connectedDeviceId: readersForUpgrade,
          },
        ],
      };
      if (customerId) {
        const response = await updateFirmwareService(
          payload,
          customerId,
          selectedGateway
        );
        if (response.status?.code === 202) {
          const correlationId = response.data.correlationId;
          setModal(false);
          dispatch(setUpgradeFirmwareProgressModalOpen(true));
          dispatch(
            setRunningProcesses({
              [correlationId]: { isCompleted: false, type: "upgrade", name:runningProcesses?getNextGroupName(runningProcesses):"" },
            })
          );
          dispatch(setSelectedCorrelationId(correlationId));
          dispatch(setChoosenReaders({ [correlationId]: selectedreaders }));
          connectToUpgradeFirmware(correlationId);
        }
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        showDeviceBusyNotification(
          dispatch,
          t("READERS.DEVICE_OPERATION_INPROGRESS")
        );
      } else {
        dispatch(
          setGlobalToasts([
            buildNotification(
              "Error",
              `${error.response?.data?.statusDescription}`
            ),
          ])
        );
      }
    }
  };
  useEffect(() => {
    // @ts-ignore
    if (
      !Object.keys(availableFirmware).length ||
      (selectedreaders as any)?.fwDisplayName !==
        availableFirmwareVersions?.data?.currentSoftwareVersion
    ) {
      try {
        (async () => {
          const payload = selectedreaders.map((reader) => {
            const currentSoftwareVersion = readerFirmwareErrors.includes(
              reader.fwDisplayName
            )
              ? ""
              : reader.fwDisplayName;
            return {
              deviceId: reader.id,
              currentSoftwareVersion: currentSoftwareVersion,
            };
          });
          const availableFirmwareList: any = await getAvailableFirmwareService({
            connectedDevices: payload,
          });
          if (
            availableFirmwareList &&
            availableFirmwareList.data &&
            availableFirmwareList.data!.statusCode
          ) {
            setShowError(availableFirmwareList.data.statusDescription);
          } else {
            setAvailableFirmware(availableFirmwareList);
          }
        })();
      } catch (error: any) {
        dispatch(
          setGlobalToasts([buildNotification("Error", t(error?.message))])
        );
      }
    }
  }, [availableFirmwareVersions]);

  const getReaders = () => {
    return selectedreaders.map((reader) => ({
      name: reader.id,
      firmwareVersion: reader.fwDisplayName,
      lastUpdated: reader.lastUpdatedDate,
      readerId: reader.id,
    }));
  };
  const showCurrentVersion = () => {
    if (availableFirmware?.data?.applicableSoftwareVersions.length > 1) {
      const version = availableFirmware?.data?.applicableSoftwareVersions.find(
        (item) =>
          availableFirmware?.data?.currentSoftwareVersion !==
          item.softwareVersion
      );
      return version ? true : false;
    } else if (
      availableFirmware?.data?.currentSoftwareVersion !==
      availableFirmware?.data?.applicableSoftwareVersions?.[0].softwareVersion
    ) {
      return true;
    }
  };
  const hanldeUpdateButton = () => {
    dispatch(setSelectedFirmware(clickedUpgradeVersion));
    // dispatch(setSelectedFirmware("R10.0.7.5"));
    setModal(true);
    // if (
    //   compareVersions(
    //     selectedreaders[0]?.fwDisplayName ||
    //       availableFirmware?.data?.currentSoftwareVersion,
    //     softwareVersion
    //   ) !== -1
    // ) {
    //   dispatch(setSelectedFirmware(clickedUpgradeVersion));
    //   // dispatch(setSelectedFirmware("R10.0.7.5"));
    //   setModal(true);
    // } else if (
    //   compareVersions(
    //     selectedreaders[0]?.fwDisplayName ||
    //       availableFirmware?.data?.currentSoftwareVersion,
    //     softwareVersion
    //   ) === -1
    // ) {
    //   setToasts([
    //     buildNotification("Error", t("READERS.RESET_FIRMWARE_MESSAGE")),
    //   ]);
    // }
  };

  const showNotification = () => {
    return (
      <Notification
        toasts={toasts}
        setToasts={setToasts}
        isSticky={false}
        isAutoClose={true}
      />
    );
  };
  function compareVersions(version1: string, version2: string) {
    const parts1 = version1.split(".").slice(1).map(Number);
    const parts2 = version2.split(".").slice(1).map(Number);

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
      const part1 = parts1[i] || 0;
      const part2 = parts2[i] || 0;

      if (part1 < part2) {
        return -1; // version1 is less than version2
      } else if (part1 > part2) {
        return 1; // version1 is greater than version2
      }
    }

    return 0; // versions are equal
  }
  const getVersionDescription = (version: any, currentVersion: any) => {
    if (version.preconditions && version.softwareVersion !== currentVersion) {
      const { gatewayVersions } = version.preconditions;
      const description = `${t(
        "READERS.YOU_NEED_TO_UPGRADDE_TO"
      )} ${gatewayVersions.join(", ")} ${t(
        "READERS.IN_ORDER_TO_SELECT_THIS_VERSION"
      )} `;
      return description;
    }
    return "";
  };
  return (
    <main className="hid-fixed-container__main hid-linq">
      <>
        <span className="hid-linq__header">{t("READERS.UPDATE_FIRMWARE")}</span>
        <div className="hid-flex hid-flex-ai-center hid-layout__m-02">
          <Button
            label={
              <>
                <Iconography icon="arrowLeftLong" size="Medium" /> &nbsp;
                {t("READERS.BACK_TO_READERS")}
              </>
            }
            variant="secondary"
            onClick={() => navigate(readersPath)}
            className="hid-layout__p-01"
          />
        </div>
        <div className="hid-flex">
          <div className="hid-linq__available-firmware">
            <Card className="fixed-height">
              <>
                <Typography variant={TypographyVariantEnum.H1}>
                  {t("READERS.AVAILABLE_FIRMWARE_VERSIONS")}
                </Typography>
                <Divider />
                <div className="hid-layout__m-03">
                  {!showError ? (
                    <>
                      <div className="hid-flex">
                        <Typography
                          variant={TypographyVariantEnum.BodyShortProduct}
                          className="hid-layout__mr-01"
                        >
                          {t("READERS.RELEASE_DATE")}
                        </Typography>
                        <Iconography icon="arrowUp" />
                      </div>
                      {availableFirmware &&
                        availableFirmware.data &&
                        availableFirmware.data.applicableSoftwareVersions
                          ?.length > 0 &&
                        [...availableFirmware.data.applicableSoftwareVersions] // Create a shallow copy of the array
                          .sort((a, b) =>
                            b.softwareVersion.localeCompare(
                              a.softwareVersion,
                              undefined,
                              { numeric: true }
                            )
                          )
                          .map((version: any) => (
                            <FirmwareVersions
                              version={version.softwareVersion}
                              versionDesc={getVersionDescription(
                                version,
                                availableFirmware.data.currentSoftwareVersion
                              )}
                              isCurrentVersion={
                                version?.softwareVersion ===
                                availableFirmware.data.currentSoftwareVersion
                              }
                              isSelected={
                                clickedUpgradeVersion ===
                                version.softwareVersion
                              }
                              disabled={version.preconditions || false}
                              onClick={(version: string) => {
                                if (clickedUpgradeVersion === version)
                                  setClickedUpgradeVersion("");
                                else setClickedUpgradeVersion(version);
                              }}
                            />
                          ))}
                      {availableFirmware?.data?.applicableSoftwareVersions?.length===0 ? (
                        <FirmwareVersions
                          version={
                            availableFirmware?.data?.currentSoftwareVersion
                          }
                          versionDesc={getVersionDescription(
                            availableFirmware?.data?.currentSoftwareVersion,
                            availableFirmware.data.currentSoftwareVersion
                          )}
                          isCurrentVersion={true}
                          isSelected={
                            clickedUpgradeVersion ===
                            availableFirmware?.data?.currentSoftwareVersion
                          }
                          disabled={false}
                          onClick={(version: string) => {
                            if (clickedUpgradeVersion === version)
                              setClickedUpgradeVersion("");
                            else setClickedUpgradeVersion(version);
                          }}
                        />
                      ):null}
                      {/* {availableFirmware &&
                        availableFirmware.data &&
                        showCurrentVersion() &&
                           (
                          <FirmwareVersions
                            version={
                              availableFirmware.data
                                .currentSoftwareVersion
                            }
                            versionDesc={""}
                            isCurrentVersion={true}
                            isSelected={
                              clickedUpgradeVersion ===
                              availableFirmware.data
                                .currentSoftwareVersion
                            }
                            disabled={false}
                            onClick={(version: string) => {
                              if (clickedUpgradeVersion === version)
                                setClickedUpgradeVersion("");
                              else setClickedUpgradeVersion(version);
                            }}
                          />
                          )} */}
                    </>
                  ) : (
                    <div className="hid-flex hid-flex-jc-between error-box">
                      <div className="hid-flex">
                        <Typography
                          variant={TypographyVariantEnum.Label}
                          className={"hid-spacing__ml-05"}
                        >
                          {showError}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </Card>
          </div>
          <div className="hid-layout__ml-04">
            <ReaderInfo readerData={getReaders()} />
            <div className="hid-layout__mt-04">
              <ChosenConfiguration
                firmware={clickedUpgradeVersion}
                // onUpdateClick={() => { dispatch(setSelectedFirmware(clickedUpgradeVersion)); setModal(true); }}
                onUpdateClick={() => hanldeUpdateButton()}
                // isDisabled={clickedUpgradeVersion === availableFirmware.data.currentSoftwareVersion}
                isDisabled={false}
              />
            </div>
          </div>
        </div>
      </>
      {isModal  && (
        <UpdateFirmwareModal
          selectedFirmware={selectedFirmware}
          setModal={(data) => setModal(data)}
          updateFirmware={updateFirmware}
          selectedReaders={selectedreaders}
        />
      )}
      {toasts.length > 0 && showNotification()}
    </main>
  );
}
