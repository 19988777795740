import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Header";
import OrigoFooter from "../../OrigoFooter";
import SideNavToolbar from "../../SideNavToolbar";

interface ILayout {
  children: React.ReactNode;
}
const SidenavLayout = (props: ILayout) => {
  const { children } = props;

  const routePath = useLocation();
  return (
    <>
      <Header />
      <div className="hid-fixed-container">
        <SideNavToolbar />
        {children}
      </div>
      {routePath.pathname === "/users/user-profile" && <OrigoFooter />}
    </>
  );
};
export default SidenavLayout;
