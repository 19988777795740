import React from "react";

import { NavItem } from "./interfaces";
import { getKey } from ".";
interface HeaderNav {
  navItems: Array<NavItem>;
  navitemCallback: (id: string) => void;
}
const HeaderNav = (props: HeaderNav) => {
  const { navItems, navitemCallback } = props;

  const getItemClass = (item: NavItem, isSubNavItem: boolean): string => {
    let className = "hid-header__nav__subnav-item";
    if (!isSubNavItem) {
      className = item.subNavItems?.length
        ? "hid-header__nav-item hid-header__nav-item--has-subnav"
        : "hid-header__nav-item";
    }
    return className;
  };

  const onNavItemClick = (item: NavItem) => {
    navitemCallback && navitemCallback(item.key);
  };

  const renderNavItem = (menuItems: Array<NavItem>, isSubNavItem: boolean) => {
    return (
      <ul
        className={isSubNavItem ? "hid-header__nav__subnav" : "hid-header__nav"}
      >
        {menuItems.map((item: NavItem, index) => (
          <li
            key={getKey(item.key, index)}
            className={getItemClass(item, isSubNavItem)}
          >
            <a
              href={item.href}
              className={
                isSubNavItem
                  ? "hid-header__nav__subnav-item__link"
                  : "hid-header__nav-item__link"
              }
              onClick={() => {
                !item.href && onNavItemClick(item);
              }}
              {...(item.target && { target: item.target })}
            >
              {item.label}
            </a>
            {item.subNavItems?.length
              ? renderNavItem(item.subNavItems, true)
              : null}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <nav className="hid-header__nav">
      {navItems.length <= 6 && renderNavItem(navItems, false)}
    </nav>
  );
};

export default HeaderNav;
