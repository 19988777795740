import { Dropdown, Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Template from "../../../assets/images/readersConfig.png";
import { NAMESPACE } from "../../../utils/i18nUtils";
import ConfigTemplateModal from "./configTemplateModal";

export interface IConfigModalData {
  configName: string;
  comment: string;
  orderableToggle: boolean;
  parentIdentifier: string;
  openModal: boolean;
}

export default function ReadersConfigTemplate() {
  const [configModalData, setConfigModalData] = useState<IConfigModalData>({
    configName: "",
    comment: "",
    orderableToggle: false,
    parentIdentifier: "",
    openModal: false,
  });
  const options = [
    {
      label: "SIGNO",
      value: "SIGNO",
    },
    {
      label: "SIGNO 25B ",
      value: "SIGNO 25B ",
    },
  ];
  const { t } = useTranslation(NAMESPACE.READER);
  return (
    <div className="hid-fixed-container__main" data-testid="config-template">
      <Typography variant={TypographyVariantEnum.H1}>
        {t("READERS.READER_CONFIGURATION_TEMPLATES")}
      </Typography>
      <div className="hid-origo__content-layout-background--white hid-flex  hid-flex-jc-center hid-layout__pt-10 hid-layout__pb-10 hid-layout__mt-02">
        <div className="hid-layout__my-04">
          <div className="hid-text-center">
            <img src={Template} alt="readers-template" />
          </div>
          <div className="hid-text-center">
            <Typography
              className="hid-layout__mt-04"
              variant={TypographyVariantEnum.BodyShortProduct}
            >
              {t("READERS.WHERE_READER_CONFIGURATION")}
            </Typography>
            <Typography variant={TypographyVariantEnum.BodyShortProduct}>
              {t("READERS.TEMPLATE_APPEAR")}
            </Typography>
          </div>
          <div className="hid-origo__dropdown hid-layout__mt-04">
            <Dropdown
              id="dropdown"
              placeholder={t("READERS.CREATE_READER_CONFIGURATION")}
              options={options}
              onSelect={() =>
                setConfigModalData({ ...configModalData, openModal: true })
              }
            />
          </div>
        </div>
      </div>
      {configModalData.openModal && (
        <ConfigTemplateModal
          configModalData={configModalData}
          setConfigModalData={setConfigModalData}
        />
      )}
    </div>
  );
}
