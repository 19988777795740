import { Footer } from "@hid-galaxy-ui/galaxy-react";
import { FooterVariants } from "@hid-galaxy-ui/galaxy-react/components/Footer/footerEnums";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";

export default function OrigoFooter() {
  const { t } = useTranslation(NAMESPACE.APP);
  return (
    <Footer
      mode={FooterVariants.Expanded}
      primaryText={t("ORIGO_FOOTER.PRIMARY")}
      // releaseDateText={t("ORIGO_FOOTER.RELEASE_DATE")}
      versionText={t("ORIGO_FOOTER.VERSION")}
      linkText={[
        {
          text: t("ORIGO_FOOTER.PRIVACY"),
          link: "https://www.hidglobal.com/about/privacy",
        },
        {
          text: t("ORIGO_FOOTER.TERMS_OF_USE"),
          link: "https://www.hidglobal.com/about/terms-of-use",
        },
      ]}
    />
  );
}
