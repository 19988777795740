import {
  Button,
  Iconography,
  Typography,
  TypographyVariant,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import "./_downloadGateway.scss";
import DownloadSuccess from "./downloadGatewaySuccess";
import Notification from "@hid-galaxy-ui/galaxy-react/components/Notification";
import {  useSelector } from "react-redux";
import { loginInfoData } from "../../../../reducers/userInfoReducer";
import { downloadGateway } from "../../../../services/readerAPIService";
import { buildNotification } from "../../../../utils/notification";
import { readerNotificationCloseDelay } from "../../../../utils/commonConst";

export enum OperatingSystem {
  WINDOWS = "windows",
  MAC = "Mac",
}
export const environmentMapper: { [key: string]: string } = {
  "linqcloud.dev.origo.hidcloud.com": "dev",
  "linqcloud.test.origo.hidcloud.com": "test",
};
interface IDownloadGateway {
  onCloseClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function DownloadGateway(props: IDownloadGateway) {
  const { onCloseClick } = props;
  const { t } = useTranslation(NAMESPACE.READER);
  const [toasts, setToasts] = useState([]) as any;
  const [selectedOs, setSelectedOs] = useState<OperatingSystem>(
    OperatingSystem.WINDOWS
  );
  const { selectedCustomerInfo } = useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  const [isDownloadCompleted, setIsDownloadCompleted] = useState(false);

  const getEnvironment = () => {
    const hostURL = window.location.hostname;
    return (
      environmentMapper[hostURL as keyof { [key: string]: string }] || hostURL
    );
  };
  const showNotification = () => {
    return (
      <Notification
        toasts={toasts}
        setToasts={setToasts}
        isSticky={false}
        autoClose={readerNotificationCloseDelay}
      />
    );
  };
  const download = async () => {
    if (customerId) {
      try {
        const response = await downloadGateway(customerId);

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");

        // Extract filename from response headers
        const contentDisposition = response.headers["content-disposition"];
        const match =
          contentDisposition && contentDisposition.match(/filename="(.+?)"/);
        const filename = match ? match[1] : "downloadedFile.zip";

        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        link.click();
        setToasts([
          buildNotification("Success", t("READERS.DOWNLOAD_GATEWAY_SUCCESS_MESSAGE")),
        ]);

      } catch (err) {
        console.error("Error downloading file:", err);
      }
    }
  };
  const onCloseClickHandler = () => {
    setIsDownloadCompleted(false);
  };

  return (
    <div className="hid-downloadGateway hid-downloadGateway__main-container">
      {isDownloadCompleted ? (
        <>
          <Iconography
            icon="circleX"
            className="hid-downloadGateway__close"
            size="Medium"
            onClick={onCloseClickHandler}
          />{" "}
          <DownloadSuccess onDownloadClick={download} />
        </>
      ) : (
        <div className="hid-flex hid-downloadGateway__white-inner-container">
          <>
            <div className="hid-downloadGateway__inner-container">
              <Typography className="label" variant={TypographyVariantEnum.H1}>
                {t("READERS.DOWNLOAD_GATEWAY_TITLE")}
              </Typography>
              <p className="hid-downloadGateway__p">
                {t("READERS.DOWNLOAD_GATEWAY_DESCRIPTION1")}
              </p>
              <p>{t("READERS.DOWNLOAD_GATEWAY_DESCRIPTION2")}</p>
            </div>
            <div className="hid-downloadGateway__device-os-container">
              <Typography variant={TypographyVariant.TextSmall}>
                {t("READERS.DEVEICE_OPERATING_SYSTEM")}
              </Typography>
              <div className={"hid-flex "}>
                <p
                  className={classNames(
                    "hid-typography__body--short-01 hid-spacing__p-04 cursor-pointer",
                    {
                      "operating-system":
                        selectedOs === OperatingSystem.WINDOWS,
                    }
                  )}
                  onClick={() => setSelectedOs(OperatingSystem.WINDOWS)}
                >
                  {t("READERS.WINDOWS")}
                </p>
                {/* <p
                  className={classNames(
                    "hid-typography__body--short-01 hid-spacing__p-04 cursor-pointer",
                    { "operating-system": selectedOs === OperatingSystem.MAC }
                  )}
                  onClick={() => setSelectedOs(OperatingSystem.MAC)}
                >
                  {t("READERS.MAC")}
                </p> */}
              </div>
              <Typography
                variant={TypographyVariant.TextSmall}
                className="hid-layout__mt-02"
              >
                {selectedOs === OperatingSystem.MAC
                  ? t("READERS.MAC_DESC")
                  : t("READERS.WINDOWS_DESC")}
              </Typography>
              <Button
                label={t("READERS.DOWNLOAD")}
                variant="primary"
                onClick={download}
                icon={<Iconography icon="download" />}
                className={"hid-layout__mt-05"}
              />
            </div>
          </>
        </div>
      )}
      { toasts.length > 0 && showNotification()}
    </div>
  );
}
