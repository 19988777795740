import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import dcid_icon from "../../assets/svgs/dcid_icon.svg"
import {
  homePath,
  readersPath,
  readersApplyTemplatePath,
  createDcidPath,
} from "../../utils/routes";
import SideNav from "../SideNav";
interface INavItem {
  title: string;
  iconName: string | ReactElement;
  key: string;
}
const SideNavToolbar = () => {
  const [selectedNavItem, setSelectedNavItem] = useState<string>("Readers");
  const navigate = useNavigate();

  const handleSideNavClick = (
    e: React.MouseEvent<HTMLElement>,
    key: string
  ) => {
    setSelectedNavItem(key);
    switch (key) {
      case "home":
        return navigate(homePath);
      case "Readers":
        return navigate(readersPath);
      case "SearchTemplate":
        return navigate(readersApplyTemplatePath);
      // case "deviceConfiguration":
      //   return navigate(createDcidPath);
    }
  };

  const getNavItems = (): INavItem[] => {
    return [
      {
        title: "Readers",
        iconName: "phoneIntercom",
        key: "Readers",
      },
      {
        title: "Configuration ID",
        iconName: <img src={dcid_icon} alt="Configuration ID" className="hid-spacing__mr-03"/>,
        key: "SearchTemplate",
      },
      // {
      //   title: "Device Configuration",
      //   iconName: <img src={dcid_icon} alt="search_template" />,
      //   key: "deviceConfiguration",
      // },
    ];
  };
  return (
    <>
      <SideNav
        id="sideNavToolbar"
        navItems={getNavItems()}
        mode={"Colapsed"}
        onNodeClick={handleSideNavClick}
        isToggle
        selected={selectedNavItem}
      />
    </>
  );
};
export default SideNavToolbar;
