import SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { socketUrl } from "./apiServices";

export let topic: { id: string; unsubscribe: () => void };
export var stompClient: Stomp.Client;

export const  connectToStomp = async(cb: any) => {
  try {
    var socket = new SockJS(socketUrl);
    stompClient = Stomp.over(socket);
        if (stompClient.connected) {
      cb(stompClient);
    } else {
      stompClient.connect({}, () => {
        cb(stompClient);
      },(err)=>{
        console.log(err)
      });
    }
  } catch (err) {
    console.log(err)
  }
};

export const disconnect = () => {
  stompClient.disconnect(() => {
  });
};
export const unSubscribe=(id:string)=>{
  stompClient?.unsubscribe(id);
};

export const subscribeToApplyTemplate = (correlationId: string) => {
  topic = stompClient.subscribe(
    `/topic/dcid-apply/${correlationId}`,
    function (response) {
      if (response.body) {
      }
    }
  );
};
