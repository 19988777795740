import store from "../store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { ITableData } from "../pages/Linq/readers";
import { useStore } from "react-redux";
import { IDevices } from "../pages/Linq/readers/downloadGateway/types";
export interface InitialStateDataProps {
  dicoveredReaders: {
    [key: string]: { readers: ITableData[] | null; timestamp: string };
  } | null;
  gatewayPagination?: {
    currentPage?: number;
    totalRecords?: number;
  };
  gatewayList?: IDevices[] | null;
  pageFrom: "list" | "carousel";
  selectedReaderRow: ITableData | null;
  discoveryLoading: boolean;
}


// const selectedgateway = store.getState().loginInfo.selectedGateway;
const initialState: InitialStateDataProps = {
  dicoveredReaders: null,
  gatewayPagination: {
    currentPage: 1,
    totalRecords: 0,
  },
  gatewayList: null,
  pageFrom: "list",
  selectedReaderRow: null,
  discoveryLoading: false,
};
const discoverReadersSlice = createSlice({
  name: "discoverReaders",
  initialState,
  reducers: {
    setReaders: (
      state,
      action: PayloadAction<{
        data: { readers: ITableData[]; timestamp: string };
        gateway: string;
      }>
    ) => {
      // const selectedgateway = store.getState()?.loginInfo?.selectedGateway;

      state.dicoveredReaders = {
        ...state.dicoveredReaders,
        [action.payload.gateway]: action.payload.data,
      };
    },
    setDiscoveryLoading: (state, action: PayloadAction<boolean>) => {
      state.discoveryLoading = action.payload;
    },
    resetReaders: (
      state,
      action: PayloadAction<{
        data: { readers: [] | null; timestamp: string };
        gateway: string;
      }>
    ) => {
      state.dicoveredReaders = {
        ...state.dicoveredReaders,
        [action.payload.gateway]: action.payload.data,
      };
    },
    clearReaders: (state, action: PayloadAction<void>) => {
      state.dicoveredReaders = null;
      state.gatewayPagination = {
        currentPage: 1,
        totalRecords: 0,
      };
      state.gatewayList = null;
    },
    setGatewayPagination: (
      state,
      action: PayloadAction<{
        currentPage?: number;
        totalRecords?: number;
      }>
    ) => {
      state.gatewayPagination = {
        ...state.gatewayPagination,
        ...action.payload,
      };
    },
    setGatewayList: (state, action: PayloadAction<IDevices[]>) => {
      state.gatewayList = action.payload;
    },
    setPageFrom: (state, action: PayloadAction<"list" | "carousel">) => {
      state.pageFrom = action.payload;
    },
    setSelectedReaderRow: (state, action: PayloadAction<ITableData | null>) => {
      state.selectedReaderRow = action.payload;
    },
  },
});

export default discoverReadersSlice.reducer;
export const {
  setReaders,
  resetReaders,
  clearReaders,
  setGatewayList,
  setGatewayPagination,
  setPageFrom,
  setSelectedReaderRow,
  setDiscoveryLoading,
} = discoverReadersSlice.actions;
export const discoveredReadersData = (state: RootState) =>
  state.discoveredReaders;
