import { useState } from "react";
import {
  BreadcrumbItem,
  Breadcrumbs,
  Card,
  Iconography,
  IconographySizes,
  Link,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../../utils/i18nUtils";
import DcidDocs from "../../../../../assets/svgs/dcidDocs.svg";
import DcidSearchBar from "./SearchBar";
import ReadersConfig from "../../../../../assets/images/readersConfig.png"
import ReadersConfigSvg from "../../../../../assets/svgs/readersConfig.svg"
import arrowRight from "../../../../../assets/svgs/arrowRight.svg"


const DcidCopyTemplate = () => {
  const { t } = useTranslation(NAMESPACE.READER);
  const [searchedDCIDConfig, setSearchedDCIDConfig] = useState<any>({});

  const getDate = (date: any) => {
    const newDate = new Date(date);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return newDate.getDate() + " " +  months[newDate.getMonth()] + ", " + newDate.getFullYear()
  };

  const renderSearchedDCID = searchedDCIDConfig?.metadata ? (
    <div className="hid-linq__copy-bg hid-layout__p-04 hid-flex hid-flex-jc-between hid-grid hid-spacing__mt-05">
      <div className="hid-grid__column hid-grid__column--9-sm">
        <div className="hid-flex hid-flex-jc-start">
          <div className="hid-spacing__mt-04">
          <img src={ReadersConfig}  height={"52px"} />
          </div>
          <div className="hid-spacing__ml-07">
            <Typography variant={TypographyVariantEnum.H2}>
              {searchedDCIDConfig?.metadata?.name}
            </Typography>
            <div className="hid-flex hid-spacing__mt-02">
              <div className="hid-flex hid-flex-ai-center">
                <img src={ReadersConfigSvg} />
                <div className="hid-spacing__pl-02">DCID: <span className="hid-origo__bold-text">{searchedDCIDConfig?.metadata?.deviceConfigurationIdentifier}</span></div>
              </div>
              <div className="hid-flex hid-flex-ai-center hid-spacing__ml-05">
                <Iconography icon="arrowsRepeat"></Iconography>
                <div className="hid-spacing__pl-02">Status: <span className="hid-origo__bold-text">{searchedDCIDConfig?.metadata?.status}</span></div>
              </div>
              <div className="hid-flex hid-flex-ai-center hid-spacing__ml-05">
                <Iconography icon="calendar"></Iconography>
                <div className="hid-spacing__pl-02">created: <span className="hid-origo__bold-text">{getDate(searchedDCIDConfig?.metadata?.createdTimestamp)}</span></div>
              </div>
            </div>
          </div>
        </div>
       {searchedDCIDConfig?.metadata?.comments && <div className="hid-spacing__mt-06 hid-linq__card">
          <Card
            cardLink={<a href="https://www.hidglobal.com/" className="hid-ml-auto">
            <Iconography icon="arrowUpRightFromSquare" />
          </a>}
          >
          {searchedDCIDConfig?.metadata?.comments}
          </Card>
        </div>}
      </div>
      <div className="hid-linq__border-right-02" ></div>
      <div className="hid-grid__column hid-grid__column--2-sm">
      <div className="hid-flex hid-flex-jc-start hid-linq__icon-link hid-spacing__p-03 hid-linq__border-radius-04">
        <img src={arrowRight} height={"20px"} width={"20px"} />
        <Link className="hid-spacing__ml-04">Use this DCID</Link>
      </div>
      </div>
    </div>
  ) : (
    <div className="hid-linq__copy-bg hid-layout__py-08 hid-flex hid-flex-jc-center hid-spacing__mt-05">
      <div className="hid-flex hid-flex-column hid-flex-ai-center">
        <img src={DcidDocs} />
        <div className="hid-linq__search-text">
          <Typography variant={TypographyVariantEnum.BodyShortProduct}>
            {t("DCID_COPY.SEARCH_CONFIGURATION_SUMMARY")}
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <div className="hid-fixed-container__main">
      <div className="hid-flex hid-flex-jc-between">
        <div>
          <Breadcrumbs noTrailingSlash={true}>
            <BreadcrumbItem
              name={t("READERS.DEVICE_CONFIGURATIONS")}
              href={"/"}
              isCurrentPage={false}
              breadcrumbSeparator={<Iconography icon="chevronRight" />}
            ></BreadcrumbItem>
            <BreadcrumbItem
              name={t("DCID_COPY.SELECT_COPY")}
              href={""}
              isCurrentPage={true}
              breadcrumbSeparator={<Iconography icon="chevronRight" />}
            ></BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <Link>Help</Link>
      </div>
      <div>
        <div className="hid-spacing__my-05 hid-layout__px-01">
          <Typography variant={TypographyVariantEnum.H1}>
            {t("DCID_COPY.SELECT_COPY")}
          </Typography>
        </div>
        <DcidSearchBar setSearchedDCIDConfig={setSearchedDCIDConfig} />
        {renderSearchedDCID}
      </div>
    </div>
  );
};

export default DcidCopyTemplate;
